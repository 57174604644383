import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function FormularioTreino() {
  const [aviso, setAviso] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Pegando os dados do formulário
    const formData = new FormData(e.target);
    const data = {
      tempoTreino: formData.get('tempoTreino'),
      idade: formData.get('idade'),
      peso: formData.get('peso'),
      altura: formData.get('altura'),
      objetivo: formData.get('objetivo'),
      frequencia: formData.get('frequencia'),
      localTreino: formData.get('localTreino'),
      medicamento: formData.get('medicamento'),
      dores: formData.get('dores')
    };

    // Compacta os dados
    const dadosCompactados = btoa(JSON.stringify(data));
    console.log("chegou aqui")
    // Lógica de redirecionamento com base nas respostas 

    // TEMPO DE TREINO 0-1 MESES
    if (data.tempoTreino === '1' && data.localTreino === 'academia') {
       window.location.href = `https://sun.eduzz.com/1404885?p=1&utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
 
/* const dadosDescompactados = JSON.parse(atob(dadosCompactados));
console.log(dadosDescompactados); */

} else if (data.tempoTreino === '0' && data.localTreino === 'casahalteres') {
      window.location.href = `https://darlanfurtado.com/treino-para-mulheres-que-treinam-em-casa-com-halteres-e-barras?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
    } else if (data.tempoTreino === '0' && data.localTreino === 'casapesodocorpo') {
        window.location.href = `https://darlanfurtado.com/mulheres-que-treinam-em-casa-com-peso-do-corpo?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
    } 
    // tempo de treino 2 meses
    else if (data.tempoTreino === '2' && data.localTreino === 'academia') {
        window.location.href = `https://darlanfurtado.com/treino-mulheres-iniciantes-nivel-2?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
      } else if (data.tempoTreino === '2' && data.localTreino === 'casahalteres') {
          window.location.href = `https://darlanfurtado.com/treino-para-mulheres-que-treinam-em-casa-com-halteres-e-barras-2?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
      } else if (data.tempoTreino === '2' && data.localTreino === 'casapesodocorpo') {
        window.location.href = `https://darlanfurtado.com/mulheres-que-treinam-em-casa-com-peso-do-corpo-2?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
    } 
       // tempo de treino 3 meses
       else if (data.tempoTreino === '3' && data.localTreino === 'academia') {
        window.location.href = `https://darlanfurtado.com/treino-para-mulheres-continuidade-etapa-1/?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
      } else if (data.tempoTreino === '3' && data.localTreino === 'casahalteres') {
          window.location.href = `https://darlanfurtado.com/treino-para-mulheres-que-treinam-em-casa-com-halteres-e-barras-3?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
      } else if (data.tempoTreino === '3' && data.localTreino === 'casapesodocorpo') {
        window.location.href = `https://darlanfurtado.com/mulheres-que-treinam-em-casa-com-peso-do-corpo-3?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
    } 
         // tempo de treino 4 meses 
         else if (data.tempoTreino === '4' && data.localTreino === 'academia') {
            window.location.href = `https://darlanfurtado.com/treino-para-mulheres-continuidade-etapa-2?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
          } else if (data.tempoTreino === '4' && data.localTreino === 'casahalteres') {
              window.location.href = `https://darlanfurtado.com/treino-para-mulheres-que-treinam-em-casa-com-halteres-e-barras-4?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
          } else if (data.tempoTreino === '4' && data.localTreino === 'casapesodocorpo') {
            window.location.href = `https://darlanfurtado.com/mulheres-que-treinam-em-casa-com-peso-do-corpo-4?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
        } 
     
    // tempo de treino 5 meses
    else if (data.tempoTreino === '5' && data.localTreino === 'academia') {
       window.location.href = `https://darlanfurtado.com/treino-para-mulheres-continuidade-etapa-2?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
     } else if (data.tempoTreino === '5' && data.localTreino === 'casahalteres') {
         window.location.href = `https://darlanfurtado.com/treino-para-mulheres-que-treinam-em-casa-com-halteres-e-barras-4?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
     } else if (data.tempoTreino === '5' && data.localTreino === 'casapesodocorpo') {
       window.location.href = `https://darlanfurtado.com/mulheres-que-treinam-em-casa-com-peso-do-corpo-4?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
   } 
             // tempo de treino 6 meses
             else if (data.tempoTreino === '6' && data.localTreino === 'academia') {
                window.location.href = `https://darlanfurtado.com/treino-para-mulheres-continuidade-etapa-3?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
              } else if (data.tempoTreino === '6' && data.localTreino === 'casahalteres') {
                  window.location.href = `https://darlanfurtado.com/treino-para-mulheres-que-treinam-em-casa-com-halteres-e-barras-5?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
              } else if (data.tempoTreino === '6' && data.localTreino === 'casapesodocorpo') {
                window.location.href = `https://darlanfurtado.com/mulheres-que-treinam-em-casa-com-peso-do-corpo-4?utm_content=${dadosCompactados}&utm_source=calculadoratreino`;
            } 
  };

  return (
    <div className='vh-100'>
      <div className='d-flex justify-content-center'>
        <div className="p-3 p-sm-4 m-sm-5 col-sm-11 col-md-7 col-xl-5 col-xxl-3 bg-white">
          <span className='fs-3 fw-bold'>Formulário</span>
          <br/>
          <span className=' m-1 fw-lighter text-secondary'>Dúvidas entre em contato no direct do Instagram.</span>
          <span className=' m-1 fw-lighter text-secondary'>Recomendamos que faça cada treino por cerca de 1 mês. Após esse período entre em contato conosco para dar continuidade e adquirir a próxima etapa.</span>
          <span className=' m-1 fw-lighter text-secondary'>Se achar que o treino é muito intenso, pouco intenso ou precisa de algum ajuste, entre em contato através do direct do Instagram para ajustarmos o seu treino e tirar todas suas dúvidas diretamente com Darlan Furtado.</span>
          <hr />
          <Form onSubmit={handleSubmit}>
            {/* Pergunta 1: Tempo de treino */}
            <Form.Group className="mb-3" controlId="tempoTreino">
              <Form.Label>Há quanto tempo você treina?</Form.Label>
              <Form.Select name="tempoTreino">
              <option value="1">Estou começando do zero agora</option>
          <option value="1">1 mês</option>
          <option value="2">2 meses</option>´
          <option value="3">3 meses</option>
          <option value="4">4 meses</option>
          <option value="5">5 meses</option>
          <option value="6">Mais do que 6 meses</option>
              </Form.Select>
            </Form.Group>

            {/* Pergunta 2: Idade */}
            <Form.Group className="mb-3" controlId="idade">
              <Form.Label>Qual é a sua idade?</Form.Label>
              <Form.Select name="idade" defaultValue="30">
                {Array.from({ length: 83 }, (_, i) => (
                  <option key={i} value={18 + i}>{18 + i} anos</option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Pergunta 3: Peso */}
            <Form.Group className="mb-3" controlId="peso">
              <Form.Label>Qual é o seu peso?</Form.Label>
              <Form.Select name="peso" defaultValue="60">
                {Array.from({ length: 120 }, (_, i) => (
                  <option key={i} value={35 + i}>{35 + i} kg</option>
                ))}
              </Form.Select>            </Form.Group>

            {/* Pergunta 4: Altura */}
            <Form.Group className="mb-3" controlId="altura">
              <Form.Label>Qual é a sua altura?</Form.Label>
              <Form.Select name="altura" defaultValue="165">
                {Array.from({ length: 91 }, (_, i) => (
                  <option key={i} value={130 + i}>{130 + i} cm</option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Pergunta 5: Objetivo */}
            <Form.Group className="mb-3" controlId="objetivo">
              <Form.Label>Qual é o seu objetivo com o treinamento?</Form.Label>
              <Form.Select name="objetivo">
                <option value="perda-gordura">Perda de Gordura e Ganho de Massa Muscular Simultaneamente</option>
                <option value="ganho-massa">Ganho de Massa Muscular</option>
              </Form.Select>
            </Form.Group>

            {/* Pergunta 6: Frequência de treino */}
            <Form.Group className="mb-3" controlId="frequencia">
              <Form.Label>Com que frequência você treina por semana?</Form.Label>
              <Form.Select name="frequencia">
                <option value="1-2">1-2 dias</option>
                <option value="3-4">3-4 dias</option>
                <option value="5-6">5-6 dias</option>
                <option value="7">7 dias</option>
              </Form.Select>
            </Form.Group>

            {/* Pergunta 7: Local de treino */}
            <Form.Group className="mb-3" controlId="localTreino">
              <Form.Label>Você treina em academia ou em casa?</Form.Label>
              <Form.Select name="localTreino">
                <option value="academia">Academia</option>
                <option value="casahalteres">Em casa com barras e halteres</option>
                <option value="casapesodocorpo">Em casa apenas com o peso do corpo</option>
              </Form.Select>
            </Form.Group>

            {/* Pergunta 8: Medicamentos controlados */}
            <Form.Group className="mb-3" controlId="medicamento">
              <Form.Label>Está utilizando algum medicamento controlado atualmente?</Form.Label>
              <Form.Control as="textarea" name="medicamento" maxLength={80} placeholder="Digite aqui..." defaultValue="Não"/>
            </Form.Group>

            {/* Pergunta 9: Dores nas articulações */}
            <Form.Group className="mb-3" controlId="dores">
              <Form.Label>Você sente alguma dor nas articulações ou possui alguma condição de saúde adicional?</Form.Label>
              <Form.Control as="textarea" name="dores" maxLength={80} placeholder="Digite aqui..." defaultValue="Não"/>
            </Form.Group>

            {/* Botão de envio */}
            <div className='align-items-center d-flex justify-content-center'>
              <Button className='my-2 p-2 btn btn-danger btn-lg' type="submit">Calcular o melhor treino</Button>
            </div>

            {/* Aviso (caso tenha algum feedback) */}
            <div className='align-items-center d-flex justify-content-center'>
              {aviso}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default FormularioTreino;
