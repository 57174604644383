import React, { useState } from "react";
import {Container,Navbar,NavDropdown,Nav,Tooltip, Modal, Badge,Col,NavItem,Row, Table,Card,Form, Dropdown,DropdownButton, Alert, Button   } from 'react-bootstrap';
import { Routes, Route, Link } from "react-router-dom";
const SidebarMenu = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="">
        <div>
      <Button className=" " onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {isSidebarOpen ? <i class="bi bi-x"></i> : <i class="bi bi-list"></i>}
      </Button>
    
        opçoes
     </div>
      {isSidebarOpen ? (  
        <div
        className=" bg-white rounded-end shadow text-black py-3  align-content-start  w-md-50 border-end"
        style={{ 
            position: "fixed",
           /*  top: 0, */
            left: isSidebarOpen ? "0" : "-200px",
            width: "200px",
            height: "100vh",
            backgroundColor: "#333",
            color: "#fff",
            transition: "left 0.3s ease",
        
        }}
      >
       <div className="px-3">
       <p className='text-secondary mt-4 fw-bold'><i class="bi bi-arrow-return-right"></i>Menu</p>
       
       <Link   className="fs-6 text-decoration-none text-dark " to="./produto-personalizado"><i className="bi bi-dash mx-2 "></i>Produto<br/>Personalizado 2.0</Link> 
     <hr/>
    <Link  className="fs-6 text-decoration-none text-dark" to="./pdfeditor"><i className="bi bi-dash mx-2 "></i>Produto<br/>Personalizado 1.0</Link> 
    <hr/>
    <Link  className="fs-6 text-decoration-none text-dark" to="./dieta"><i className="bi bi-dash mx-2 "></i>Calculadora<br/>de refeições</Link>
    <hr/>
    <Link  className="fs-6 text-decoration-none text-dark" to="./formulario"><i className="bi bi-dash mx-2 "></i>Calculadora<br/>de calorias</Link>
    
        
        </div>

        </div>
      ) : null}
    
    </div>
  );
};
export default SidebarMenu;