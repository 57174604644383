import { PDFDocument, StandardFonts, rgb, PDFLib,degrees } from 'pdf-lib'
import download from 'downloadjs';
import {Container,Navbar,NavDropdown,Nav, Col,NavItem,Row, Card, Dropdown,DropdownButton, Alert, Button, Table, Form   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from "react";
import { PureComponent } from 'react';
import { render } from '@testing-library/react';
import { Buffer } from 'buffer';
import { useState } from "react";
import mc1 from "./planilhas/Treino para Mulheres - Continuidade Etapa 1.pdf";
import mc2 from "./planilhas/Treino para Mulheres - Continuidade Etapa 2.pdf";
import mc3 from "./planilhas/Treino para Mulheres - Continuidade Etapa 3.pdf";
import mc4 from "./planilhas/Treino para Mulheres - Continuidade Etapa 4.pdf";
import mc5 from "./planilhas/Treino para Mulheres - Continuidade Etapa 5.pdf";
import mc6 from "./planilhas/Treino para Mulheres - Continuidade Etapa 6.pdf";
import mc7 from "./planilhas/Treino para Mulheres - Continuidade Etapa 7.pdf";
import mc8 from "./planilhas/Treino para Mulheres - Continuidade Etapa 8.pdf";
import mc9 from "./planilhas/Treino para Mulheres - Continuidade Etapa 9.pdf";
import mc10 from "./planilhas/Treino para Mulheres - Continuidade Etapa 10.pdf";
import mc11 from "./planilhas/Treino para Mulheres - Continuidade Etapa 11.pdf";
import mc12 from "./planilhas/Treino para Mulheres - Continuidade Etapa 12.pdf";
import mc13 from "./planilhas/Treino para Mulheres - Continuidade Etapa 13.pdf";
import mc14 from "./planilhas/Treino para Mulheres - Continuidade Etapa 14.pdf";
import hc1 from "./planilhas/Treino para Homens - Continuidade Etapa 1.pdf";
import hc2 from "./planilhas/Treino para Homens - Continuidade Etapa 2.pdf";
import hc3 from "./planilhas/Treino para Homens - Continuidade Etapa 3.pdf";
import hc4 from "./planilhas/Treino para Homens - Continuidade Etapa 4.pdf";
import hc5 from "./planilhas/Treino para Homens - Continuidade Etapa 5.pdf";
import hc6 from "./planilhas/Treino para Homens - Continuidade Etapa 6.pdf";
import hc7 from "./planilhas/Treino para Homens - Continuidade Etapa 7.pdf";
import hc8 from "./planilhas/Treino para Homens - Continuidade Etapa 8.pdf";
import hc9 from "./planilhas/Treino para Homens - Continuidade Etapa 9.pdf";
import msp1 from "./planilhas/Treino para Mulheres que Treinam em casa sem peso.pdf";
import msp2 from "./planilhas/Treino para Mulheres que treinam em casa com peso do corpo - Etapa 2.pdf";
import msp3 from "./planilhas/Treino para Mulheres que treinam em casa com peso do corpo - Etapa 3.pdf";
import msp4 from "./planilhas/Treino para Mulheres que treinam em casa com peso do corpo - Etapa 4.pdf";
import thi1 from "./planilhas/Treino para Homens Iniciantes nível 1.pdf";
import thi2 from "./planilhas/Treino para Homens Iniciantes nivel 2.pdf";
import tmi1 from "./planilhas/Treino para Mulheres iniciantes nivel 1.pdf";
import tmi2 from "./planilhas/Treino para Mulheres iniciantes nivel 2.pdf";
import mhb1 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 1.pdf";
import mhb2 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 2.pdf";
import mhb3 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 3.pdf";
import mhb4 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 4.pdf";
import mhb5 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 5.pdf";
import mhb6 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 6.pdf";
import m17 from "./planilhas/17-metodos-darlan-furtado.pdf";
import tmpg from "./planilhas/Treino para mulheres com ênfase nas pernas e glúteos.pdf";
/* cardapios - inicio */
import alfa from "./cardapios/Cardápio Alfa Hipertrofia.pdf";
import bravo from "./cardapios/Cardápio Bravo Perda de Gordura.pdf";
import charlie from "./cardapios/Cardápio Charlie Hipertrofia.pdf";
import delta from "./cardapios/Cardápio Delta Perda de Gordura.pdf";
import echo from "./cardapios/Cardápio Echo Hipertrofia.pdf";
import foxtrot from "./cardapios/Cardápio Foxtrot Perda de Gordura.pdf";
import golf from "./cardapios/Cardápio Golf Hipertrofia.pdf";
import hotel from "./cardapios/Cardápio Hotel Perda de Gordura.pdf";
import india from "./cardapios/Cardápio India Hipertrofia.pdf";
import juliet from "./cardapios/Cardápio Juliet Perda de Gordura.pdf";
import kilo from "./cardapios/Cardápio Kilo Hipertrofia.pdf";
import lima from "./cardapios/Cardápio Lima Perda de Gordura.pdf";
import mike from "./cardapios/Cardápio Mike Hipertrofia.pdf";
import november from "./cardapios/Cardápio November Perda de Gordura.pdf";
/* cardapios - fim */


/* import { Deslogar } from './Administrador';
 */
import listaProdutos from './ListaProdutos';



async function createPdf() {
    
    // Create a new PDFDocument
    const pdfDoc = await PDFDocument.create()
    
    // Embed the Times Roman font
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    
    // Add a blank page to the document
    const page = pdfDoc.addPage()
    
    // Get the width and height of the page
    const { width, height } = page.getSize()
    
    // Draw a string of text toward the top of the page
    const fontSize = 30
    page.drawText('Creating PDFs in JavaScript is awesome!', {
        x: 50,
        y: height - 4 * fontSize,
        size: fontSize,
        font: timesRomanFont,
        color: rgb(0, 0.53, 0.71),
    })
    
    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()
    
    // Trigger the browser to download the PDF document
    download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    
    return (<p>asasasas</p>);
}


async function modifyPdf (e) {
    console.log(e.target.nome.value)
  console.log(e.target.email.value)
  console.log("produto:" + e.target.produto.value)
  console.log("---")

    const parametro = e.target.produto.value
    console.log(parametro)
    const url = parametro
    console.log(listaProdutos[3].link)
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { width, height } = firstPage.getSize()
    
    //   console.log(e.target.nome.value);
    //  console.log(e.target.email.value);
    //   console.log(e.target.produto.value);
    
    var roots = pages.map(printar);
    
    
    function printar(num){
        
        let today = new Date()
        let date = today.getDate() + '/' + parseInt(today.getMonth() + 1) + '/' + today.getFullYear()
        //  console.log(date)
        
        num.drawText('Nome:' + e.target.nome.value + ' | Email:' + e.target.email.value + '', {
            x: 14,
            y: 80,
            size: 13,
            
            color: rgb(0.95, 0.1, 0.1),
            rotate: degrees(90),
        })
    }
    
    
    const pdfBytes = await pdfDoc.save()
    download(pdfBytes, e.target.email.value + ".pdf", "application/pdf");
    
}

function Pdfeditor () {
    let esdd = 1;
    
    
    const [selectedValue, setSelectedValue] = useState([]);
    const handleChange = (e) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }
    
    
    return (
        <div className=' bg-light p-3 p-lg-5'>
        <p className='fs-3 fw-bold mt-3'>Gerador de produto pdf personalizado</p>
        <span>Digite os dados do cliente e selecione o produto para gerar o PDF com marca d'água</span>
        <hr/>
        
        <Form onSubmit={(e) => {modifyPdf(e); e.preventDefault();}}>
        <Form.Group  className="mb-3" controlId="dados">
        
        <Form.Label className='mt-3'  >Nome do cliente</Form.Label>
        <Form.Control type="text" placeholder="Digite aqui o nome do cliente"  name="nome"  required />
        <Form.Label className='mt-3'  >Email do cliente</Form.Label>
        <Form.Control type="email" placeholder="Digite aqui o nome email do cliente"  name="email" required />
        
        <Form.Label className='mt-3'  >Selecione o produto</Form.Label>
        
        <Form.Select name="produto" className='mt-2' required>
        <option>Escolha o produto</option>   
        {listaProdutos.map((item, index) => 
            <option value={item.link} key={item.link} >{item.nome}</option>    
            )}
            </Form.Select>
            
            <br/>

            <button   className='mt-4  btn btn-danger btn-lg ' type="submit">Baixar produto PDF</button>
            
            
            
            
            
            
            </Form.Group>
            
            
            </Form>
            <hr/>
           {/*  <button   className='mt-5  btn btn-secondary'  onClick={(e) => {Deslogar(); window.location.reload();}} >Deslogar</button>
             */}</div>
            
            );
        }
        
        export default Pdfeditor;
