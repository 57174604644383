import { Container, Navbar, NavDropdown, Nav, NavLink, NavItem,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Deslogar } from './Administrador';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Dieta from './Dieta';
import SidebarAdministrador from './SidebarAdministrador';

// MENU SUPERIOR
function MenuAdministrador() {
  const [menuAberto, setMenuAberto] = useState(false); // Estado para controlar se o menu está aberto
  const navigate = useNavigate();

  // Função para fechar o menu
  const fecharMenu = () => {
    setMenuAberto(false);
  };

  return (
    <>
      <Navbar className="border-bottom bg-white shadow-sm " fixed="top" expand="md">
        <Container >
          <Link className="nav-link fs-5 " to="./" onClick={fecharMenu} >
            Painel de Controle
            
          </Link>
          <Navbar.Toggle onClick={() => setMenuAberto(!menuAberto)} className="shadow-sm border rounded bg-light " />
          <Navbar.Collapse in={menuAberto} className="px-3 ">
          <NavDropdown.Divider />
        
            
        <Nav className="me-auto">
              <Link className="nav-link text-black " to="./produto-personalizado" onClick={fecharMenu}>
              <i className="bi bi-dash mx-1 "></i>Produto Personalizado 2.0
              </Link>
              <Link className="nav-link text-black" to="./pdfeditor" onClick={fecharMenu}>
              <i className="bi bi-dash mx-1 "></i>Produto Personalizado 1.0
              </Link>
              <Link className="nav-link text-black" to="./dieta" onClick={fecharMenu}>
              <i className="bi bi-dash mx-1 "></i>Calculadora de refeições
              </Link>
              <Link className="nav-link text-black" to="./formulario" onClick={fecharMenu}>
              <i className="bi bi-dash mx-1 "></i>Calculadora de calorias
              </Link>
              <Link className="nav-link text-black" to="./vendas" onClick={fecharMenu}>
              <i className="bi bi-dash mx-1 "></i>Vendas
              </Link>
            </Nav>
            <hr/>
            <Nav>
              <Nav.Link onClick={(e) => { Deslogar(); window.location.reload(); }} className='   text-center   '>
                <div className='border rounded px-2 '>Deslogar</div>
                </Nav.Link>
            </Nav> 
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default MenuAdministrador;
