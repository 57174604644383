import { PDFDocument, StandardFonts, rgb, PDFLib,degrees } from 'pdf-lib'
import download from 'downloadjs';
import {Container,Badge,Navbar,NavDropdown,Nav, Col,NavItem,Row, Card, Dropdown,DropdownButton, Alert, Button, Table, Form, Modal   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { PureComponent } from 'react';
import { render } from '@testing-library/react';
import { Buffer } from 'buffer';
import { useState, useEffect  } from "react";
import {Link, useNavigate} from 'react-router-dom';
/* import { Deslogar } from './Administrador'; */
import listaProdutos from './ListaProdutos';


export async function modifypdf (e) {
    const option = JSON.parse(e.target.produto.value);
    console.log("aqui>>" + e.target.produto.value)
    /*   console.log(option.nome)
    console.log(option.value) */
    const parametro = option.value
    const url = parametro
    /*  console.log(listaProdutos[3].link) */
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { width, height } = firstPage.getSize()
    var roots = pages.map(printar);        
    function printar(num){
        let today = new Date()
        let date = today.getDate() + '/' + parseInt(today.getMonth() + 1) + '/' + today.getFullYear()
        var emailcorte = e.target.email.value.substring(0, e.target.email.value.indexOf('@')) + ' ' + e.target.email.value.substring(e.target.email.value.indexOf('@'), e.target.email.value.length) 
        var cpf = e.target.cpf.value
        var cpftexto = 'CPF: ' + cpf
        var nometamanho = e.target.nome.value.length
        var emailtamanho = e.target.email.value.length
        var nomewidth = helveticaFont.widthOfTextAtSize(e.target.nome.value, 35);
        var cpfwidth = helveticaFont.widthOfTextAtSize(cpftexto, 55);
        var nomewidth = helveticaFont.widthOfTextAtSize(e.target.nome.value, 35);
        // SEM CPF
        if(cpf === "") {
            if(nometamanho < 250 ){
                for(let i = -200; i < 850; i=i+110){
                    num.drawText(e.target.nome.value , {
                        x: (width - nomewidth)/2,
                        y: i,
                        size: 35,
                        color: rgb(0, 0, 0),
                        opacity: 0.00,
                        rotate: degrees(0),
                    })  
                    
                }
                num.drawText('Nome:' + e.target.nome.value + ' | Email: ' + e.target.email.value, {
                    x: 10,
                    y: 70,
                    size: 11,
                    color: rgb(0.53, 0.53, 0.53, 0.53),
                    opacity: 0.5,
                    rotate: degrees(90),
                })  
            }
            else if(nometamanho < 290 ){
                for(let i = -200; i < 850; i=i+110){
                    num.drawText('' + e.target.nome.value + '\n\n', {
                        x: 60,
                        y: i,
                        size: 42,
                        color: rgb(0, 0, 0),
                        opacity: 0.00,
                        rotate: degrees(25),
                    })        
                }
            }
            else {
                for(let i = -200; i < 850; i=i+110){
                    num.drawText('' + e.target.nome.value + '\n\n', {
                        x: 0,
                        y: i,
                        size: 45,
                        color: rgb(0, 0, 0),
                        opacity: 0.00,
                        rotate: degrees(25),
                    })
                }
            } 
        }
        // SEM CPF FIM
        //com cpf inicio
        else if (cpf != ""){
            if(nometamanho < 250 ){
                for(let i = -200; i < 850; i=i+110){
                    num.drawText(e.target.nome.value , {
                        x: (width - nomewidth)/2,
                        y: i,
                        size: 35,
                        
                        color: rgb(0, 0, 0),
                        opacity: 0.00,
                        rotate: degrees(0),
                    })  
                    num.drawText(cpftexto, {
                        x: (width - cpfwidth)/2,
                        y: i + 150,
                        size: 55,
                       
                        color: rgb(0, 0, 0),
                        opacity: 0.00,
                        rotate: degrees(0),
                    }) 
                }
                num.drawText('Nome:' + e.target.nome.value + ' | CPF: ' + cpf + ' | Email: ' + e.target.email.value, {
                    x: 10,
                    y: 70,
                    size: 11,
                    color: rgb(0.53, 0.53, 0.53, 0.53),
                    opacity: 0.5, 
                    rotate: degrees(90),
                })  
            }
            else if(nometamanho < 290 ){
                for(let i = -200; i < 850; i=i+110){
                    num.drawText('' + e.target.nome.value + '\n\n' + 'CPF: ' + cpf, {
                        x: 60,
                        y: i,
                        size: 42,
                        color: rgb(0, 0, 0),
                        opacity: 0.00,
                        rotate: degrees(25),
                    })        
                }
            }
            else {
                for(let i = -200; i < 850; i=i+110){
                    num.drawText('' + e.target.nome.value + '\n\n' + 'CPF: ' + cpf, {
                        x: 0,
                        y: i,
                        size: 45,
                        color: rgb(0, 0, 0),
                        opacity: 0.00,
                        rotate: degrees(25),
                    })
                }
            }
        }
        // com cpf fim
    }
    const pdfBytes = await pdfDoc.save()
    download(pdfBytes, e.target.email.value + ".pdf", "application/pdf");
    console.log('Download feito')
}


function ProdutoPersonalizado () {
    const [botao, setBotao] = useState('baixar');
    const [aviso,setAviso] = useState(null)
    const [confirmado, setConfirmado] = useState(false)
    const navigate = useNavigate();
    const config = {
        headers: { Authorization: `Bearer ` + localStorage.getItem('token') }
    };
    /*  
    useEffect(() => {
        setTimeout(() => {
            setAviso(null);
        }, 3000);
    }, []); */
    const [show, setShow] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const handleChange = (e) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }
    function enviarForm (e) {
        /*   console.log('enviar form inicio')
        console.log(e.target.botao1.value) */
        if (e.target.botao1.value == 'download'){
            
            modifypdf(e)}
            else if(e.target.botao1.value == 'enviaremail'){
                const option = JSON.parse(e.target.produto.value);
                // console.log(e.target.produto.value)
/*                 axios.get('https://www.darlanfurtado.com.br:3000/send-email/' + e.target.email.value + '/' + e.target.nome.value + '/' + e.target.cpf.value + '/' + option.nome, config)
 */                axios.get('https://api.furtadoteam.com.br/send-email/' + e.target.email.value + '/' + e.target.nome.value + '/' + e.target.cpf.value + '/' + option.nome, config)
                //  axios.get('http://34.205.85.95:3000/send-email/' + e.target.email.value + '/' + e.target.nome.value + '/' + e.target.cpf.value + '/' + option.nome, config)
                .then(function (response) {
                    // handle success
                    console.log("Email enviado com Sucesso!")
                    setAviso(<span>Email enviado com Sucesso!</span>)
                })
                .catch(function (error) {
                    // handle error
                    console.log("Email não enviado, erro!")
                    setAviso(<p>Algum erro aconteceu, atualize a pagina!!</p>)
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
            }
        }
        const[event, setEvent] = useState('')
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        function confirmei(e)
        {
            // console.log('>' + botao)
            setEvent(e)
            if (botao == 'enviaremail')   {
                handleShow()
            } else if ( botao == 'download'){
                confirmar()
            }
            setConfirmado(true)
            /* setBotao('enviaremail')
            */
        }
        function confirmar(){
            handleClose()
            if(confirmado) {enviarForm(event)}
        }
        const [emailcliente, setemailcliente] = useState('');
        const [nomecliente, setnomecliente] = useState('');
        const [cpfcliente, setcpfcliente] = useState('');
        const handleInputChangenome = (event) => {
            setnomecliente(event.target.value);
        };
        const handleInputChangeemail = (event) => {
            setemailcliente(event.target.value);
        };
        const handleInputChangecpf = (event) => {
            setcpfcliente(event.target.value);
        };
        /* const handleSubmit = () => {
            console.log('Nome' + nomecliente);
            console.log('Email' + emailcliente);
            console.log('CPF' + cpfcliente);
        }; */
        function ContadorBadge() {
            setTimeout(function() {
                setAviso(null);
            }, 3300); 
            return '';
        }
        function AvisoBadge(){
            if (aviso != null) {
                return (
                    <div>
                    <div  style={{ position: 'relative' }}>
                    <Alert  variant="danger" className='shadow ' style={{ position: 'fixed', top: 90, right: 20 }}>
                    {aviso}</Alert></div>
                    <ContadorBadge />
                    </div>
                    )
                } else {
                    return ''
                }
            }
            return (
                <div className='mt-5 bg-white p-5'>
                <p className='fs-3 fw-bold'>Gerador de produto pdf personalizado</p>
                <span>Digite os dados do cliente e selecione o produto para gerar o PDF com marca d'água</span>
                <hr/> 
                <Form onSubmit={(e) => {confirmei(e); e.preventDefault();}}>
                <Form.Group  className="mb-3" controlId="dados">
                <Form.Label className='mt-3' >Nome do cliente <i className='text-danger'>(obrigatório)</i></Form.Label>
                <Form.Control type="text" placeholder="Digite aqui o nome do cliente" onChange={handleInputChangenome}  value={nomecliente} name="nome"  />
                <Form.Label className='mt-3'  >Email do cliente <i className='text-danger'>(obrigatório)</i></Form.Label>
                <Form.Control type="text" onChange={handleInputChangeemail}  placeholder="Digite aqui o nome email do cliente"  name="email"  value={emailcliente} />
                <Form.Label className='mt-3'  >CPF do cliente</Form.Label>
                <Form.Control type="text" placeholder="Digite aqui o CPF cliente" onChange={handleInputChangecpf}   name="cpf"  value={cpfcliente} />
                <Form.Label className='mt-3'  >Selecione o produto <i className='text-danger'>(obrigatório)</i></Form.Label>
                <Form.Select name="produto" className='mt-2'  >
                <option>Selecione o produto</option>   
                {listaProdutos.map((item, index) => 
                    <option value={JSON.stringify({value :item.link,nome: item.nome})} key={item.link}>{item.nome}</option>    
                    ) }
                    </Form.Select>
                    <br/>
                    <div className='container '>
                    <div className='row justify-content-center'>
                    <button className='mt-3  p-2 btn btn-danger btn-md col-sm-12 col-md-6  col-lg-4' name='botao1' value={botao} type="submit" onClick={() => setBotao('download')}>Fazer download localmente<br/>(clique duas vezes)</button>
                    <div className='col-lg-3'/>
                    <button className='mt-3 p-2 btn btn-primary btn-md col-sm-12 col-md-6 col-lg-4' name='botao2' value={botao} type="submit" onClick={() => setBotao('enviaremail')}  >Enviar para email do cliente<br/>(obrigatório cpf)</button>
                    </div>
                    </div>
                    <AvisoBadge />
                    </Form.Group>
                    </Form>
                    <div >
                    </div>
                    <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    >
                    <Modal.Header closeButton>
                    <Modal.Title>Confirmar dados</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <span>Tem certeza que você deseja enviar o produto para o cliente abaixo?</span><br/><hr />
                    Nome: {nomecliente}<br/>
                    Email: <b className='text-primary'>{emailcliente}</b><br/>
                    CPF: {cpfcliente}<br/><hr />
                    <span>O produto será enviado em anexo para o email do cliente.</span>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => confirmar()}>Confirmar envio</Button>
                    </Modal.Footer>
                    </Modal>
                    <hr/>
                    {/*  <Button   className='mt-5  btn btn-secondary' onClick={(e) => { navigate('../adicionar-produto')}}>Adicionar novo produto na base</Button>
                */}
                <br/>
                {/* <button   className='mt-5  btn btn-secondary'  onClick={(e) => {Deslogar(); window.location.reload();}} >Deslogar</button>
            */}
            {/*    
            <>
            <Button variant="primary" onClick={handleShow}>
            TEXTO BOTAO
            </Button>
            <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            I will not close if you click outside me. Don't even try to press
            escape key.
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            Close
            </Button>
            <Button variant="primary" type="submit" >Confirmar envio</Button>
            </Modal.Footer>
            </Modal>
        </> */}
        </div>
        );
    }
    export default ProdutoPersonalizado;
