import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { PDFDocument, rgb } from 'pdf-lib';
import { Document, Page, pdfjs } from 'react-pdf';
import './teste.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'; // Define o caminho do worker



const Teste = () => {
    const [pdf, setPdf] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const pdfBytes = await file.arrayBuffer();
      setPdf(pdfBytes);
    }
  };

  const generatePdf = async () => {
    const pdfDoc = await PDFDocument.load(pdf);
    const page = pdfDoc.getPage(0);
    const { name, age, email } = formData;

    const helveticaFont = await pdfDoc.embedFont('Helvetica');

    page.drawText(name, {
      x: 100,
      y: 600,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(age, {
      x: 100,
      y: 560,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(email, {
      x: 100,
      y: 520,
      size: 24,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    const pdfBytes = await pdfDoc.save();
    setPdf(pdfBytes);
  };

  return (
    <Container style={{ marginTop: '30px' }}>
      <Row>
        <Col md={6}>
          <Form>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Age:</Form.Label>
              <Form.Control
                type="text"
                name="age"
                value={formData.age}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
          <Form.Group>
            <Form.Label>Upload PDF:</Form.Label>
            <Form.Control
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <div
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '500px',
            }}
          >
            {pdf ? (
              <Document file={{ data: pdf }} workerSrc="/pdf.worker.js">
                <Page pageNumber={1} width={400} />
              </Document>
            ) : (
              <p style={{ margin: '0', fontSize: '18px', color: '#555' }}>
                Upload a PDF to see the preview
              </p>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <button
            style={{ marginTop: '20px' }}
            onClick={generatePdf}
            disabled={!pdf}
          >
            Generate PDF with Form Data
          </button>
        </Col>
      </Row>
    </Container>
  );
};
export default Teste;