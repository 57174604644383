import {
  Container,
  Table,
  Navbar,
  NavDropdown,
  Nav,
  Col,
  NavItem,
  Row,
  Card,
  Form,
  Dropdown,
  DropdownButton,
  Alert,
  Button,
  Badge,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useOutletContext,
} from "react-router-dom";

import React, { useState, useEffect } from "react";

const alimentos = [
  {
    nome: "Carne Magra",
    pesoref: 100,
    quantidade: null,
    pesounidade: null,
    calorias: 111,
    proteinas: 24,
    gorduras: 1.6,
    carboidratos: 0,
    medida: "g",
  },
  {
    nome: "Shake de Whey",
    pesoref: 1,
    quantidade: null,
    pesounidade: null,
    calorias: 11100,
    proteinas: 2400,
    gorduras: 160,
    carboidratos: 0,
    medida: "unidade",
  },
  /* fiz uma gambiarra aqui no shake pra ficar igual a carne magra */

  {
    nome: "Sardinha em Lata",
    pesoref: 100,
    quantidade: 1,
    pesounidade: "100 gramas",
    calorias: 132.8,
    proteinas: 7.44,
    gorduras: 11.2,
    carboidratos: 0,
    medida: "g",
  },

  {
    nome: "Ovo de galinha",
    pesoref: 100,
    quantidade: 1,
    pesounidade: "45 gramas",
    calorias: 146,
    proteinas: 13.3,
    gorduras: 9.5,
    carboidratos: 0.6,
    medida: "g",
  },
  {
    nome: "Clara de ovo",
    pesoref: 100,
    quantidade: "1 clara",
    pesounidade: "30g",
    calorias: 52,
    proteinas: 10.9,
    gorduras: 0.17,
    carboidratos: 0.73,
    medida: "g",
  },

  {
    nome: "Azeite de Oliva",
    pesoref: 100,
    quantidade: "1 colher de chá",
    pesounidade: "5ml",
    calorias: 884,
    proteinas: 0,
    gorduras: 100,
    carboidratos: 0,
    medida: "ml",
  },
  {
    nome: "Arroz cozido",
    pesoref: 100,
    quantidade: null,
    pesounidade: null,
    calorias: 128,
    proteinas: 2.5,
    gorduras: 0.2,
    carboidratos: 28.1,
    medida: "g",
  },
  {
    nome: "Feijão carioca ou preto cozido",
    pesoref: 100,
    quantidade: "1 concha cheia",
    pesounidade: "140 gramas",
    calorias: 77,
    proteinas: 4.5,
    gorduras: 0.5,
    carboidratos: 14,
    medida: "g",
  },
  {
    nome: "Batata doce cozida",
    pesoref: 100,
    quantidade: null,
    pesounidade: null,
    calorias: 77,
    proteinas: 0.6,
    gorduras: 0.1,
    carboidratos: 18.4,
    medida: "g",
  },
  {
    nome: "Batata inglesa cozida",
    pesoref: 100,
    quantidade: null,
    pesounidade: null,
    calorias: 86,
    proteinas: 1.71,
    gorduras: 0.1,
    carboidratos: 20,
    medida: "g",
  },

  
 

  {
    nome: "Queijo prato",
    pesoref: 100,
    quantidade: "1 fatia",
    pesounidade: "20 gramas",
    calorias: 360,
    proteinas: 22.7,
    gorduras: 29.1,
    carboidratos: 1.9,
    medida: "g",
  },

  {
    nome: "Queijo branco",
    pesoref: 100,
    quantidade: "1 fatia",
    pesounidade: "30 gramas",
    calorias: 220,
    proteinas: 17.4,
    gorduras: 16.4,
    carboidratos: 3.4,
    medida: "g",
  },

  
  {
    nome: "Café com adoçante(opcional)",
    pesoref: null,
    quantidade: null,
    pesounidade: null,
    calorias: null,
    proteinas: null,
    gorduras: null,
    carboidratos: null,
    medida: null,
  },
  {
    nome: "Salada verde à vontade",
    pesoref: null,
    quantidade: null,
    pesounidade: null,
    calorias: null,
    proteinas: null,
    gorduras: null,
    carboidratos: null,
    medida: null,
  },

  

  {
    nome: "Pão de forma tradicional",
    pesoref: 100,
    quantidade: "1 fatia",
    pesounidade: "25 gramas",
    calorias: 250,
    proteinas: 9.2,
    gorduras: 2.6,
    carboidratos: 48,
    medida: "g",
  },
  {
    nome: "Pão de queijo Forno de Minas",
    pesoref: 100,
    quantidade: "1 pão",
    pesounidade: "25 gramas",
    calorias: 294,
    proteinas: 7.2,
    gorduras: 15.6,
    carboidratos: 32,
    medida: "g",
  },
  {
    nome: "Leite Desnatado",
    pesoref: 100,
    quantidade: "1 copo",
    pesounidade: "200ml",
    calorias: 35,
    proteinas: 3.2,
    gorduras: 0.4,
    carboidratos: 4.7,
    medida: "ml",
  },
  {
    nome: "Leite Integral",
    pesoref: 100,
    quantidade: "1 copo",
    pesounidade: "200ml",
    calorias: 57,
    proteinas: 3,
    gorduras: 3,
    carboidratos: 4.5,
    medida: "ml",
  },
  {
    nome: "Tapioca",
    pesoref: 100,
    quantidade: "3 colheres de sopa",
    pesounidade: "60 gramas",
    calorias: 242,
    proteinas: 0,
    gorduras: 0,
    carboidratos: 60,
    medida: "g",
  },
  
  {
    nome: "Iogurte natural desnatado nestle",
    pesoref: 100,
    quantidade: "1 unidade",
    pesounidade: "160g",
    calorias: 40,
    proteinas: 3.625,
    gorduras: 0,
    carboidratos: 4.875,
    medida: "g",
  },
  

 
  {
    nome: "Pasta de amendoim",
    pesoref: 100,
    quantidade: "1 colher de copa",
    pesounidade: "20g",
    calorias: 455,
    proteinas: 19.5,
    gorduras: 30.5,
    carboidratos: 33.5,
    medida: "g",
  },
  {
    nome: "Abacate",
    pesoref: 100,
    quantidade: "1 abacate",
    pesounidade: "430g",
    calorias: 96,
    proteinas: 1.2,
    gorduras: 12,
    carboidratos: 6,
    medida: "g",
  },

  {
    nome: "Mamão Papaia",
    pesoref: 100,
    quantidade: "1 mamão",
    pesounidade: "310g",
    calorias: 40,
    proteinas: 0.5,
    gorduras: 0.1,
    carboidratos: 10.4,
    medida: "g",
  },
  {
    nome: "Morango",
    pesoref: 100,
    quantidade: "1 morango",
    pesounidade: "20g",
    calorias: 30,
    proteinas: 0.9,
    gorduras: 0.3,
    carboidratos: 6.8,
    medida: "g",
  },
  {
    nome: "Laranja",
    pesoref: 100,
    quantidade: "",
    pesounidade: "",
    calorias: 63,
    proteinas: 1.3,
    gorduras: 0.3,
    carboidratos: 15.5,
    medida: "g",
  },
  {
    nome: "Banana prata",
    pesoref: 100,
    quantidade: 1,
    pesounidade: "55 gramas",
    calorias: 99,
    proteinas: 1.3,
    gorduras: 0.3,
    carboidratos: 25.8,
    medida: "g",
  },
  {
    nome: "Pêra willians",
    pesoref: 100,
    quantidade: 1,
    pesounidade: "130 gramas",
    calorias: 53,
    proteinas: 0.6,
    gorduras: 0.1,
    carboidratos: 14,
    medida: "g",
  },

  {
    nome: "Melancia",
    pesoref: 100,
    quantidade: "1 fatia",
    pesounidade: "200 gramas",
    calorias: 33,
    proteinas: 0.9,
    gorduras: 0,
    carboidratos: 8.1,
    medida: "g",
  },
  {
    nome: "Melão",
    pesoref: 100,
    quantidade: "1 fatia",
    pesounidade: "90 gramas",
    calorias: 29,
    proteinas: 0.7,
    gorduras: 0,
    carboidratos: 7.5,
    medida: "g",
  },
  {
    nome: "Mexerica",
    pesoref: 100,
    quantidade: "1 mexerica",
    pesounidade: "180 gramas",
    calorias: 58,
    proteinas: 0.9,
    gorduras: 0.1,
    carboidratos: 13.34,
    medida: "g",
  },

];

function Dieta() {
  const [selecionados, setSelecionados] = useState([]);
  const [quantidades, setQuantidades] = useState({});
  const [totalCalorias, setTotalCalorias] = useState(0);
  const [totalProteinas, setTotalProteinas] = useState(0);
  const [totalGorduras, setTotalGorduras] = useState(0);
  const [totalCarboidratos, setTotalCarboidratos] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  const [refeicaoNome, setRefeicaoNome] = useState("");

  const handleRefeicaoNomeChange = (event) => {
    setRefeicaoNome(event.target.value);
  };

  const handleAlimentoChange = (event) => {
    const { value } = event.target;
    const selecionado = alimentos.find((alimento) => alimento.nome === value);
    if (!selecionado) return;

    setQuantidades({ ...quantidades, [selecionado.nome]: "" });
    setSelecionados([...selecionados, selecionado]);
  };

  const handleQuantidadeChange = (event, alimento) => {
    const { value } = event.target;
    setQuantidades({ ...quantidades, [alimento.nome]: value });
  };

  const handleRemoverAlimento = (alimento) => {
    const novosSelecionados = selecionados.filter(
      (item) => item.nome !== alimento.nome
    );
    const novasQuantidades = { ...quantidades };
    delete novasQuantidades[alimento.nome];

    setSelecionados(novosSelecionados);
    setQuantidades(novasQuantidades);
  };

  const calcularTotal = () => {
    let calorias = 0;
    let proteinas = 0;
    let gorduras = 0;
    let carboidratos = 0;

    selecionados.forEach((alimento) => {
      const quantidade = quantidades[alimento.nome];
      if (!quantidade) return;

      const gramas = parseFloat(quantidade);
      calorias += (alimento.calorias / 100) * gramas;
      proteinas += (alimento.proteinas / 100) * gramas;
      gorduras += (alimento.gorduras / 100) * gramas;
      carboidratos += (alimento.carboidratos / 100) * gramas;
    });

    setTotalCalorias(calorias);
    setTotalProteinas(proteinas);
    setTotalGorduras(gorduras);
    setTotalCarboidratos(carboidratos);
    setShowAlert(true);
  };

  const calcularProporcoes = () => {
    const total = totalProteinas + totalGorduras + totalCarboidratos;

    const Proteína = (totalProteinas / total) * 100;
    const Gordura = (totalGorduras / total) * 100;
    const Carboidrato = (totalCarboidratos / total) * 100;

    return {
      Proteína,
      Gordura,
      Carboidrato,
    };
  };

  return (
    <Container>
      <h1 className="mt-2 mb-3 text-center">Calculadora Nutricional</h1>
      <hr />

      <h6>Digite o nome da Refeição(opcional)</h6>
      <Form.Control
        type="text"
        value={refeicaoNome}
        onChange={handleRefeicaoNomeChange}
      />

      <Form className="">
        <Row className="mb-5">
          <Form.Group as={Col}>
            <Form.Label>
              Selecione um alimento para adicionar a refeição:
            </Form.Label>
            <Form.Control
              as="select"
              onChange={handleAlimentoChange}
              className="border bg-white shadow-sm "
            >
              <option value="">Selecione</option>
              {alimentos.map((alimento, index) => (
                <option key={index} value={alimento.nome}>
                  <b> {alimento.nome} </b>
                  {/*                     <span className=" fw-lighter fst-italic text-muted badge " >(a cada {alimento.pesoref}g - {alimento.calorias}kcal, P:{alimento.proteinas}g, C:{alimento.carboidratos}g, G:{alimento.gorduras}g )</span><br/>
                   */}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row className="  mb-3">
          <Col xs={4} sm={6}>
            <b>Alimento</b>
          </Col>
          <Col xs={5} sm={4}>
            <span className=" text-center">
              <b>Peso em gramas ou ml</b>
            </span>
          </Col>
          <Col xs={2} sm={2}></Col>
        </Row>

        {selecionados.map((alimento) => (
          <Row key={alimento.nome} className="bg-light py-2 rounded mb-1 ">
            <Col xs={5} sm={6}>
              <Form.Label>{alimento.nome}</Form.Label>
              <br />
              {alimento.quantidade ? (
                <Form.Label>
                  <span className=" fw-lighter fst-italic text-muted  ">
                    {alimento.quantidade} {alimento.nome.split(" ")[0]} ={" "}
                    {alimento.pesounidade}{" "}
                  </span>
                </Form.Label>
              ) : (
                <div></div>
              )}
            </Col>
            <Col xs={3} sm={4}>
              <Form.Control
                type="number"
                value={quantidades[alimento.nome] || ""}
                onChange={(event) => handleQuantidadeChange(event, alimento)}
              />
            </Col>
            <Col xs={2} sm={2}>
              <Button
                variant="danger"
                onClick={() => handleRemoverAlimento(alimento)}
                className=" "
              >
                Remover
              </Button>
            </Col>
          </Row>
        ))}
        <Button className="my-2" variant="primary" onClick={calcularTotal}>
          Calcular
        </Button>
      </Form>

      {showAlert && (
        <div className="mt-4 p-4 rounded bg-white  text-black shadow">
          <p className="text-center fw-bold fs-5">{refeicaoNome}</p>
          <Row>
            <Col
              xs={12}
              sm={8}
              md={6}
              lg={6}
              xl={4}
              className="border rounded shadow-sm p-4  "
            >
              <h6>Refeição:</h6>
              {selecionados.map((alimento) => (
                <span key={alimento.nome}>
                  <i class="bi bi-arrow-return-right"></i>{" "}
                  <b>{alimento.nome}</b>: {quantidades[alimento.nome]}{" "}
                  {alimento.medida}
                  <br />
                  {/*         <span className=" fw-lighter fst-italic text-muted badge " >({alimento.nome.split(" ")[0]}, a cada {alimento.pesoref}g - {alimento.calorias}kcal, P:{alimento.proteinas}g, C:{alimento.carboidratos}g, G:{alimento.gorduras}g )</span><br/>
                   */}{" "}
                  {/*  {alimento.nome.split(" ")[0]} = {alimento.pesounidade} */}
                </span>
              ))}
            </Col>
            <Col className="shadow-sm p-4">
              <h6>Resultado:</h6>
              <span>Calorias: {totalCalorias.toFixed(1)} kcal</span>
              <br />
              <span>Proteínas: {totalProteinas.toFixed(1)} g</span>
              <br />
              <span>Gorduras: {totalGorduras.toFixed(1)} g</span>
              <br />
              <span>Carboidratos: {totalCarboidratos.toFixed(1)} g</span>
            </Col>
            <Col className="shadow-sm p-4">
              <h6>Proporções de macronutrientes:</h6>
              {Object.entries(calcularProporcoes()).map(
                ([macronutriente, proporcao]) => (
                  <span key={macronutriente}>
                    {macronutriente}: {proporcao.toFixed(0)}%
                    <br />
                  </span>
                )
              )}
            </Col>

            <Col xs={12} className="">
              <span className=" fw-lighter fst-italic fw-bold text-muted badge mt-2 ">
                Informação nutricional dos alimentos usados:
              </span>
              <br />
              {selecionados.map((alimento) => (
                <span key={alimento.nome}>
                  <span className=" fw-lighter fst-italic text-muted badge ">
                    ({alimento.nome /* .split(" ")[0] */}, a cada{" "}
                    {alimento.pesoref}g - {alimento.calorias}kcal, P:
                    {alimento.proteinas}g, C:{alimento.carboidratos}g, G:
                    {alimento.gorduras}g )
                  </span>
                  <br />
                  {/*  {alimento.nome.split(" ")[0]} = {alimento.pesounidade} */}
                </span>
              ))}
            </Col>
          </Row>
        </div>
      )}

      <hr />
      <h3>Tabela de Referência</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Calorias</th>
            <th>Proteínas</th>
            <th>Gorduras</th>
            <th>Carboidratos</th>
            <th>Peso Referência(g)</th>
            <th>Quantidade/Unidade</th>
            <th>Peso de cada Unidade(g)</th>
          </tr>
        </thead>
        <tbody>
          {alimentos.map((alimento, index) => (
            <tr key={index}>
              <td>{alimento.nome}</td>
              <td>{alimento.calorias}</td>
              <td>{alimento.proteinas}</td>
              <td>{alimento.gorduras}</td>
              <td>{alimento.carboidratos}</td>
              <td>{alimento.pesoref}</td>
              <td>{alimento.quantidade}</td>
              <td>{alimento.pesounidade}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
export default Dieta;
