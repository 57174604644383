import {Container,Navbar,NavDropdown,Nav,Tooltip, Col,NavItem,Row, Card,Form, Dropdown,DropdownButton, Alert, Button   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { render } from "react-dom";
import {
    BrowserRouter,
    Routes,Link,
    Route,    
    Outlet,
    useOutletContext
} from "react-router-dom";

import React, { useState, useEffect } from 'react';



function Formulario() {
    const [tbm, setTbm] = useState(0)
    const [get, setGet] = useState(0)
    const [qtdkcal, setQtdkcal] = useState(0)
    const [objetivo, setObjetivo] = useState(0)
    const [aviso, setAviso] = useState('');
    const [melhorcardapio, setMelhorcardapio] = useState(0)
    const [melhorcardapiocal, setMelhorcardapiocal] = useState(0)
    useEffect(() => {
        const timer = setTimeout(() => {
            setAviso('');
          }, 6000);
      
          return () => clearTimeout(timer);
    }, [aviso]);
    

    useEffect(() => {

       
       if(objetivo === 'perdadegordura'){
            if (qtdkcal > 1200 && qtdkcal <= 1550) {
                 /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                 setMelhorcardapio('Cardápio Bravo Perda de Gordura')
                 setMelhorcardapiocal(1400)
               }
            if (qtdkcal > 1550 && qtdkcal <= 1850) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Delta Perda de Gordura')
                setMelhorcardapiocal(1700)
            }
            if (qtdkcal > 1850 && qtdkcal <= 2150) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Foxtrot Perda de Gordura')
                setMelhorcardapiocal(2000)
            }  
            if (qtdkcal > 2150 && qtdkcal <= 2450) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Hotel Perda de Gordura')
                setMelhorcardapiocal(2300)
            }  
            if (qtdkcal > 2450 && qtdkcal <= 2750) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Juliet Perda de Gordura')
                setMelhorcardapiocal(2600)
            } 
            if (qtdkcal > 2750 && qtdkcal <= 3050) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Lima Perda de Gordura')
                setMelhorcardapiocal(2900)
            } 
            if (qtdkcal > 3050 && qtdkcal <= 3500) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio November Perda de Gordura')
                setMelhorcardapiocal(3200)
            }
            if (qtdkcal > 3350 && qtdkcal <= 3650) {
              /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
              setMelhorcardapio('Cardápio Papa Perda de Gordura')
              setMelhorcardapiocal(3500)
          }
          if (qtdkcal > 3650 && qtdkcal <= 4000) {
            /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
            setMelhorcardapio('Cardápio Romeu Perda de Gordura')
            setMelhorcardapiocal(3800)
        }
        }  
         

        if(objetivo === 'hipertrofia'){
            if (qtdkcal >= 1200 && qtdkcal < 1550) {
                 /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                 setMelhorcardapio('Cardápio Alfa Hipertrofia')
                 setMelhorcardapiocal(1400)
               }
            if (qtdkcal >= 1550 && qtdkcal < 1850) {
                 /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';   */
                 setMelhorcardapio('Cardápio Charlie Hipertrofia')
                 setMelhorcardapiocal(1700)

            }
            if (qtdkcal >= 1850 && qtdkcal < 2150) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Echo Hipertrofia')
                setMelhorcardapiocal(2000)

            }  
            if (qtdkcal >= 2150 && qtdkcal < 2450) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Golf Hipertrofia')
                setMelhorcardapiocal(2300)
            }  
            if (qtdkcal >= 2450 && qtdkcal < 2750) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio India Hipertrofia')
                setMelhorcardapiocal(2600)
            } 
            if (qtdkcal >= 2750 && qtdkcal < 3050) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Kilo Hipertrofia')
                setMelhorcardapiocal(2900)
            } 
            if (qtdkcal >= 3050 && qtdkcal < 3500) {
                /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
                setMelhorcardapio('Cardápio Mike Hipertrofia')
                setMelhorcardapiocal(3200)
            }
            if (qtdkcal >= 3350 && qtdkcal < 3650) {
              /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
              setMelhorcardapio('Cardápio Oscar Hipertrofia')
              setMelhorcardapiocal(3500)
          }
          if (qtdkcal >= 3650 && qtdkcal < 4000) {
            /*  window.location.href = 'https://sun.eduzz.com/1922830&p=1';  */
            setMelhorcardapio('Cardápio Quebec Hipertrofia')
            setMelhorcardapiocal(3800)
        }
        }
       

      }, [qtdkcal]);
    
     /* function CalculoTBM(e){
        
        if(e.target.genero.value == "Masculino"){
            setTbm(66+(13.7*e.target.peso.value)+(5*e.target.altura.value)-(6.8*e.target.idade.value))
            setGet(tbm*e.target.fa.value) 
            if( e.target.objetivo.value === "perdadegordura") {
              
                setQtdkcal(get-500)
            } else if (e.target.objetivo.value === "hipertrofia"){
                setQtdkcal(get+500)
            }
        }

        if(e.target.genero.value == "Feminino"){
            setTbm(665+(9.6*e.target.peso.value)+(1.8*e.target.altura.value)-(4.7*e.target.idade.value))
             setGet(tbm*e.target.fa.value) 
             if( e.target.objetivo.value === "perdadegordura") {
                setQtdkcal(get-500)

            } else if (e.target.objetivo.value === "hipertrofia"){
                setQtdkcal(get+500)
            }
        } } */
         function CalculoTBM(e) {
            e.preventDefault();
            setObjetivo(e.target.objetivo.value)
            
            const genero = e.target.genero.value;
            const idade = e.target.idade.value;
            const peso = e.target.peso.value;
            const altura = e.target.altura.value;
            const fa = e.target.fa.value;
           /*  const objetivo = e.target.objetivo.value; */
        
            let calculatedTbm = 0;
            let calculatedGet = 0;
            let calculatedQtdkcal = 0;

            /*  ** VALIDADORES INICIO ** */

           

            /*  VALIDADOR IDADE INICIO */
            if (!e.target.idade.value) {
                setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A idade é obrigatória!</p></div>) 
                return;
              }
            if (e.target.idade.value > 100) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A idade tem que ser menor!</p></div>) 
                return;
              }
              if (e.target.idade.value < 18) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Obrigatório ser maior de 18 anos!</p></div>) 
                return;
              }
              
              if (isNaN(e.target.idade.value)) {
                setAviso(<div><p className='text-danger text-center  mt-2'>A idade tem que ser um número!</p></div>) 
                return;
              }
            /*  VALIDADOR IDADE FIM */

             /*  VALIDADOR PESO INICIO */
             if (!e.target.peso.value) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>O peso é obrigatório!</p></div>) 
                return;
              }
             if (e.target.peso.value > 300) {
                setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>Peso muito alto!</p></div>) 
                return;
              }
              if (e.target.peso.value < 30) {
                setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>O peso tem que ser maior!</p></div>) 
                return;
              }
              
              if (isNaN(e.target.peso.value)) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>O peso tem que ser um número inteiro! <br/>Obs: Não use vírgula ou ponto, somente números.</p></div>) 
                return;
              }
            /*  VALIDADOR PESO FIM */
             /*  VALIDADOR ALTURA INICIO */
             if (!e.target.altura.value) {
                setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A altura é obrigatória!</p></div>) 
                return;
              }
              if (e.target.altura.value > 250) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A altura está alta demais!</p></div>) 
                return;
              }
              if (e.target.altura.value <= 80) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A altura tem que ser maior! <br/> Obs: Não use vírgula ou ponto, somente números.<br/> Lembre-se: 1 metro é igual a 100 cm</p></div>) 
                return;
              }
              if (isNaN(e.target.altura.value)) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A altura tem que ser um número!<br/> Obs: Não use vírgula ou ponto, somente números.<br/> Lembre-se: 1 metro é igual a 100 cm</p></div>) 
                return;
              }
            /*  VALIDADOR ALTURA FIM */

            /*  VALIDADOR GENERO INICIO */
            if (e.target.genero.value !== 'Masculino' && e.target.genero.value !== 'Feminino' ) {
                setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>Erro!</p></div>) 
                return;
              }
              if (!e.target.genero.value) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Erro!</p></div>) 
                return;
              }
            /*  VALIDADOR GENERO FIM */

             /*  VALIDADOR FA  INICIO */
             if (e.target.fa.value !== '1.375' && e.target.fa.value !== '1.55' && e.target.fa.value !== '1.725' && e.target.fa.value !== '1.9' ) {
                console.log(e.target.fa.value)
                setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>Erro!</p></div>) 
                return;
              }
              if (!e.target.fa.value) {
                setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Erro!</p></div>) 
                return;
              }
            /*  VALIDADOR FA FIM */
            /*  ** VALIDADORES FIM ** */

            if (genero === "Masculino") {
              calculatedTbm = 66 + 13.7 * peso + 5 * altura - 6.8 * idade;
            } else if (genero === "Feminino") {
              calculatedTbm = 665 + 9.6 * peso + 1.8 * altura - 4.7 * idade;
            }
        
            calculatedGet = calculatedTbm * fa;
        
            if (e.target.objetivo.value === "perdadegordura") {

             /*  if(calculatedGet - 500 < 2000) {
                calculatedQtdkcal = calculatedGet - 500;
              } else {
                calculatedQtdkcal = calculatedGet - 800;
              } */
              if(calculatedGet <= 2000) {
                calculatedQtdkcal = calculatedGet - 500;
              } else if (calculatedGet > 2000 && calculatedGet <= 2200){
                calculatedQtdkcal = calculatedGet - 700;
              } else if (calculatedGet > 2200 && calculatedGet <= 2600){
                calculatedQtdkcal = calculatedGet - 800;
              } else if (calculatedGet > 2600 && calculatedGet <= 3000){
                calculatedQtdkcal = calculatedGet - 900;
              } else if (calculatedGet > 3000 ){
                calculatedQtdkcal = calculatedGet - 1000;
              }
              
            } else if (e.target.objetivo.value === "hipertrofia") {
              calculatedQtdkcal = calculatedGet + 500;
            }
            setQtdkcal(calculatedQtdkcal);
            setTbm(calculatedTbm);
            setGet(calculatedGet);
           
         } 

        /* O CODIGO ABAIXO É A CALCULADORA ANTES DO TESTE DE AUMENTAR O DEFICIT EM 300 KCAL PARA PESSOAS QUE ANTES TERIAM O CARDAPIO ACIMA DE 2000KCAL */
            /* if (genero === "Masculino") {
              calculatedTbm = 66 + 13.7 * peso + 5 * altura - 6.8 * idade;
            } else if (genero === "Feminino") {
              calculatedTbm = 665 + 9.6 * peso + 1.8 * altura - 4.7 * idade;
            }
        
            calculatedGet = calculatedTbm * fa;
        
            if (e.target.objetivo.value === "perdadegordura") {
              calculatedQtdkcal = calculatedGet - 500;
            } else if (e.target.objetivo.value === "hipertrofia") {
              calculatedQtdkcal = calculatedGet + 500;
            }
            setQtdkcal(calculatedQtdkcal);
            setTbm(calculatedTbm);
            setGet(calculatedGet);
           
         }  */
    
     
    

    
    return (
            <div className='vh-100 bg-light'>
            <div className='d-flex justify-content-center'>
            <div className="p-3 p-sm-4 m-sm-5 mt-5 mb-5 shadow   col-sm-11 col-md-7 col-xl-5 col-xxl-3 bg-white">
         {/*    <p className='fs-4 fw-bold bg-danger text-white text-center p-1'>Formulário </p> */}  

        {/* <div className=' bg-white p-5 '> */}
        <p className='fs-3 fw-bold'> Formulário </p>
      
        <span className='fw-lighter text-secondary'>Preencha os campos abaixo para saber informações como TBM, GET, Qtd de calorias ideal e o Cardápio ideal.</span>
        <hr/>
        {/* <Form onSubmit={(e) => {CalculoTBM(e); e.preventDefault();}}> */}
        <Form onSubmit={(e) => {CalculoTBM(e); e.preventDefault();}}>
        
        <Form.Group  className="mb-3" controlId="dados">
        <Form.Group className="">
        <Form.Label>Gênero</Form.Label>
        <Form.Select name="genero">
        <option>Feminino</option>
        <option>Masculino</option>
       
        </Form.Select>
        </Form.Group>
        
        <Form.Label className='mt-3' >Idade</Form.Label>
        <Form.Control type="text" placeholder="Exemplo: 32"  name="idade"   />

        <Form.Label className='mt-3'  >Peso(kg)</Form.Label>
        <Form.Control type="text" placeholder="Exemplo: 70 kg"  name="peso" />

        <Form.Label className='mt-3'  >Altura(cm)</Form.Label>
        <Form.Control type="text" placeholder="Exemplo: 168 cm"  name="altura" />
       

        <Form.Label className='mt-3'  >Frequência de atividade fisica</Form.Label>
        <Form.Select name="fa">
        <option value="1.375">Leve (Exercício Físico 1-3 dias por semana)</option>
        <option value="1.55">Moderado (Exercício Físico 3-5 dias por semana)</option>
        <option value="1.725">Intenso (Exercício Físico 6-7 dias por semana)</option> 
        <option value="1.9">Extremamente Intenso (Exercício Físico 6-7 dias por semana e trabalho com força física)</option>
        </Form.Select>
        <Form.Label className='mt-3'  >Seu objetivo</Form.Label>
        <Form.Select name="objetivo">
         <option value="perdadegordura">Perda de Gordura</option>
        <option value="hipertrofia">Hipertrofia (Ganho de massa magra)</option>
       </Form.Select>
        <br/>
        <div className='align-items-center d-flex justify-content-center'>
        <button   className='my-2 p-2 btn btn-danger btn-lg ' type="submit">Calcular dados</button>
        </div>
        <div className='align-items-center d-flex justify-content-center'>
        {aviso}
        </div>
        </Form.Group>
        </Form>

        <div className='border rounded bg-light shadow-sm p-2'>
        *Resultado<br/>
        TBM: {tbm.toFixed(0)} kcal<br/>
        GET: {get.toFixed(0)} kcal<br/>
        Qtd de calorias ideal por dia: {qtdkcal.toFixed(0)} kcal<br/>
        Cardápio ideal: {melhorcardapio}<br/>
        Qtd de calorias do cardápio ideal: {melhorcardapiocal} kcal<br/>
        {/* Cardapio ideal: {melhorcardapio}<br/><br/> */}
        <span className='text-danger fw-lighter'>Obs: Esse resultado não aparecerá para o cliente.
        </span>
        </div>

        <Outlet />
        </div> </div> </div>
        );
    }
    
    export default Formulario;