import {Container,Navbar,NavDropdown,Nav, Col,NavItem,Row, Card, Dropdown,DropdownButton, Alert, Button, Table, Form   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from "react";
import { useState, useEffect } from 'react';
import { render } from '@testing-library/react';
/* import ProdutosAdministrador from '../Administrador/ProdutosAdministrador'; */
import axios from 'axios'
import {
  BrowserRouter,
  Routes,
  Navigate,
  useLocation,
  Route,
  Outlet
} from "react-router-dom";
import Administrador from './Administrador';
/* import Painel from './Painel'; */

/* const axios = require('axios'); */

 
function RotaPrivadaAdmin (){
  const [retorno, setRetorno] = useState(<Outlet />);
  
  var token = localStorage.getItem('token');

  useEffect(() => {
   /*  axios.get(`https://api.furtadoteam.com/verificar/`, {  */
/* */   axios.get(`https://api.furtadoteam.com.br/verificar/`, { 
 
  
  headers: {
    'Authorization': `Bearer ${token}`
  },  
})
.then(function (response) {
  // handle success
  if (response.data){console.log('User loaded')}
})
.catch(function (error) {
  // handle error
  console.log(error);
  setRetorno(<Navigate to="../admlogin"  replace />)
})
.then(function () {
  // always executed
});
  }, []);
  
return retorno

}
 
{/* BKP
   function RotaPrivadaAdmin() {
    useEffect(() => {
      const verificarToken = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('https://www.darlanfurtado.com.br:3000/verificar/', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (!response.data) {
            console.log('User not loaded');
            window.location.href = '../admlogin';
          }
        } catch (error) {
          console.log(error);
          window.location.href = '../admlogin';
        }
      };
  
      verificarToken();
    }, []);
  
    // Redirecionar o usuário antes da renderização do componente
    return <Navigate to="../admlogin" replace />;
  }
  ---------
*/}
 

export default RotaPrivadaAdmin