import {Container,Navbar,NavDropdown,Nav, Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
 import MenuAdministrador from './MenuAdministrador'; 
import * as React from "react";
import { useState } from "react";
import axios from 'axios';
import { Routes, Route, Link,BrowserRouter, Outlet,Navigate, useNavigate } from "react-router-dom";
/* import ProdutosAdministrador from './ProdutosAdministrador'; */
import { Form, Button } from 'react-bootstrap';
import SidebarAdministrador from './SidebarAdministrador';
/* import CriarProdutos from './CriarProdutos';
import VisaoGeralVendas from './VisaoGeralVendas';
import VisaoGeralProdutos from './VisaoGeralProdutos'
import AdicionarProdutos from './AdicionarProdutos'; */
/* import Painel from './Painel'; */
import ProdutoPersonalizado from './ProdutoPersonalizado';
/* import AdministradorClientes from './AdministradorClientes'
import AdicionarProdutoPersonalizado from './AdicionarProdutoPersonalizado' */
 import Pdfeditor from './Pdfeditor';
 import Painel from './Painel';
import Dieta from './Dieta';
import Formulario from './Formulario';
import Vendas from './Vendas';

export async function Isauthenticated() {
  
  try {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    };
    const response = await axios.get('http://api.furtadoteam.com/verificar', config).then(function (response) {
    // handle success
    console.log(response);
    // return true
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  });;
  
  
} catch (error) {
  console.log(error);
  return false;
}
}

function ProtectedContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  if (!isAuthenticated) {
    return <div>Você não está autorizado a ver este conteúdo</div>;
  }
  
  return <div>Conteúdo protegido</div>;
}

export function Deslogar() {
  localStorage.setItem('token', null )
  const token = localStorage.getItem('token');
  /* console.log(token + 'VAMOS VER') */
  console.log('Deslogando...')
  return (
    <div>
    
    <Navigate to="./" replace={true} />
    </div>
    );
  }
  
  
  function RetornaLogin() {
    return (<Navigate to="/admlogin" replace={true} />)
  }
  
  
  
  function Administrador() {
    
    
    
    return (
      <div className="">
      <MenuAdministrador /> 
      <Container className='bg-light' fluid>
      <Row>
      <Col xs={2}  className='vh-auto min-vh-100 d-none d-lg-block' style={{"width": "200px",   "backgroundColor": "#053D4E"}}>
      <SidebarAdministrador  />
      </Col>
      <Col  className=' mt-5' lg={9} xl={"10"} xxl={"10"} >
      <Routes>
      <Route path="/*" element={<Painel/>}  />
        {/*
      <Route path="criar-produto" element={<AdicionarProdutos/>}  />
      <Route path="produtos" element={<VisaoGeralProdutos/>}  />
      <Route path="vendas" element={<VisaoGeralVendas/>} />
      <Route path="reembolsos"  />
      <Route path="clientes"  element={<AdministradorClientes/>}/>
      <Route path="relatorios"  />
      <Route path="despesas"  />
      <Route path="lucros"  /> */}
      <Route path="produto-personalizado" element={<ProdutoPersonalizado/>} />
     <Route path="pdfeditor" element={<Pdfeditor/>} /> 
     <Route path="dieta" element={<Dieta/>} /> 
     <Route path="formulario" element={<Formulario/>} /> 
     <Route path="vendas" element={<Vendas/>} />  
      {/* <Route path="adicionar-produto" element={<AdicionarProdutoPersonalizado />} /> */}
      </Routes>
      </Col>
      </Row>
      </Container>
      {/* <CriarProdutos />  <AdicionarProdutos />  */}
      </div>
      );
    }
    
    
    
    
    export default Administrador;