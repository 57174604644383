import {Container,Navbar,NavDropdown,Nav,Tooltip, Col,NavItem,Row, Card,Form, Dropdown,DropdownButton, Alert, Button   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,Link,
  Route,    
  Outlet,
  useOutletContext
} from "react-router-dom";

import React, { useState, useEffect } from 'react';

const axios = require('axios');



function FormularioCalculadora() {
  const [tbm, setTbm] = useState(0)
  const [get, setGet] = useState(0)
  const [qtdkcal, setQtdkcal] = useState(0)
  const [objetivo, setObjetivo] = useState(0)
  const [aviso, setAviso] = useState('')
  const [cardapioideal, setCardapioideal] = useState('')
  
  
  
  const [melhorcardapio, setMelhorcardapio] = useState(0)
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setAviso('');
    }, 5000);
    
    return () => clearTimeout(timer);
  }, [aviso]);
  
  useEffect(() => {
    setTimeout(() => {
      
    }, 5000);
    
  }, [melhorcardapio]);
  
  
  useEffect(() => {
    
    
    if(objetivo === 'perdadegordura'){
      
      setAviso(
        <div >
        <Alert  variant="danger" className='shadow d-flex justify-content-center row mt-3' >
        
        <div className="spinner-border col-12" role="status">
        <span className="visually-hidden">Loading...</span>
        </div>
        <div className="">
        Estamos procurando o cardápio ideal para você!
        <br/>Em instantes você será redirecionado(a) para adquirir o cardápio.</div>
        </Alert>
        </div>
        )
        
        
        if (qtdkcal > 1200 & qtdkcal <= 1550) {
          
          /* console.log('melhor cardapio de 1400') */
          setMelhorcardapio(1400)
          /*  Cardápio Bravo Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1963111?p=1'; 
          }, 5000);
        } else if (qtdkcal > 1550 && qtdkcal <= 1850) {
          
          /* console.log('melhor cardapio de 1700') */
          setMelhorcardapio(1700)
          /*  Cardápio Delta Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1963112?p=1'; 
          }, 5000);
        } else if (qtdkcal > 1850 && qtdkcal <= 2150) {
          
          /*  console.log('melhor cardapio de 2000') */
          setMelhorcardapio(2000)
          /*      Cardápio Foxtrot Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1963113?p=1'; 
          }, 5000);
          
        } else if (qtdkcal > 2150 && qtdkcal <= 2450) {
          
          /* console.log('melhor cardapio de 2300') */
          setMelhorcardapio(2300)
          /*      Cardápio Hotel Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1963115?p=1'; 
          }, 5000);
        } else if (qtdkcal > 2450 && qtdkcal <= 2750) {
          
          /*  console.log('melhor cardapio de 2600') */
          setMelhorcardapio(2600)
          /*      Cardápio Juliet Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1963161?p=1'; 
          }, 5000);
        } else if (qtdkcal > 2750 && qtdkcal <= 3050) {
          
          /*  console.log('melhor cardapio de 2900') */
          setMelhorcardapio(2900)
          /*      Cardápio Lima Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1963168?p=1'; 
          }, 5000);
        } else if (qtdkcal > 3050 && qtdkcal <= 3350) {
          
          /* link aqui   */
          /*  console.log('melhor cardapio de 3200') */
          setMelhorcardapio(3200)
          /*      Cardápio November Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1963171?p=1'; 
          }, 5000);
        } else if (qtdkcal > 3350 && qtdkcal <= 3650) {
          
          /* link aqui   */
          /*  console.log('melhor cardapio de 3500') */
          setMelhorcardapio(3500)
          /*      Cardápio Papa Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1964579?p=1'; 
          }, 5000);
        } else if (qtdkcal > 3650 && qtdkcal <= 4000) { /* PROJETEI 200 A MAIS AO INVES DE 150, QUANDO FOR ADICIONAR O CARDAPIO SIERRA MUDAR PARA 3950 ONDE ESTA 4000 */
          
          /* link aqui   */
          /*  console.log('melhor cardapio de 3500') */
          setMelhorcardapio(3800)
          /*      Cardápio Romeu Perda de Gordura */
          setTimeout(() => {
            window.parent.location.href = 'https://sun.eduzz.com/1964581?p=1'; 
          }, 5000);
        } else {
          setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>Entre em contato através do direct do Instagram e mencione seu dados para analisarmos o seu caso!</p></div>)
        }
      }  
      
      if(objetivo === 'hipertrofia'){
        
        setAviso(
          <div >
          <Alert  variant="danger" className='shadow d-flex justify-content-center row mt-3' >
          
          <div className="spinner-border col-12" role="status">
          <span className="visually-hidden">Loading...</span>
          </div>
          <div className="">
          Estamos procurando o cardápio ideal para você!
          <br/>Em instantes você será redirecionado(a) para adquirir o cardápio.</div>
          </Alert>
          </div>
          )
          
          
          
          
          
          if (qtdkcal >= 1200 & qtdkcal < 1550) {
            /* alfa */
            
            /* console.log('melhor cardapio de 1400') */
            setMelhorcardapio(1400)
            /*      Cardápio Alfa Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1962786?p=1'; 
            }, 5000);
          } else if (qtdkcal >= 1550 && qtdkcal < 1850) {
            
            /* console.log('melhor cardapio de 1700') */
            setMelhorcardapio(1700)
            /*      Cardápio Charlie Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1963078?p=1'; 
            }, 5000);
            
          } else if (qtdkcal >= 1850 && qtdkcal < 2150) {
            
            /*  console.log('melhor cardapio de 2000') */
            setMelhorcardapio(2000)
            /*      Cardápio Echo Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1963081?p=1'; 
            }, 5000);
          } else if (qtdkcal >= 2150 && qtdkcal < 2450) {
            /* golf */
            
            /*    console.log('melhor cardapio de 2300') */
            setMelhorcardapio(2300)
            /*      Cardápio Golf Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1962974?p=1'; 
            }, 5000);
            
          } else if (qtdkcal >= 2450 && qtdkcal < 2750) {
            
            /*   console.log('melhor cardapio de 2600') */
            setMelhorcardapio(2600)
            /*      Cardápio India Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1963085?p=1'; 
            }, 5000);
          }  else if (qtdkcal >= 2750 && qtdkcal < 3050) {
            
            /*       console.log('melhor cardapio de 2900') */
            setMelhorcardapio(2900)
            /*      Cardápio Kilo Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1963088?p=1'; 
            }, 5000);
          }  else if (qtdkcal >= 3050 && qtdkcal < 3350) {
            
            
            /*    console.log('melhor cardapio de 3200') */
            setMelhorcardapio(3200)
            /*      Cardápio Mike  Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1963099?p=1'; 
            }, 5000);
          } else if (qtdkcal >= 3350 && qtdkcal < 3650) {
            
            
            /*    console.log('melhor cardapio de 3500') */
            setMelhorcardapio(3500)
            /*      Cardápio Oscar  Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1964557?p=1'; 
            }, 5000);
          } else if (qtdkcal >= 3650 && qtdkcal < 4000) {  /* PROJETEI 200 A MAIS AO INVES DE 150, QUANDO FOR ADICIONAR O CARDAPIO SIERRA MUDAR PARA 3950 ONDE ESTA 4000 */
            
            
            /*    console.log('melhor cardapio de 3800') */
            setMelhorcardapio(3800)
            /*      Cardápio Quebec  Hipertrofia */
            setTimeout(() => {
              window.parent.location.href = 'https://sun.eduzz.com/1964574?p=1'; 
            }, 5000);
          } else {
            setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>Entre em contato através do direct do Instagram e mencione seu dados para analisarmos o seu caso!</p></div>)
          }
        }
        
        
      }, [qtdkcal]);
      
      /* function CalculoTBM(e){
        
        if(e.target.genero.value == "Masculino"){
          setTbm(66+(13.7*e.target.peso.value)+(5*e.target.altura.value)-(6.8*e.target.idade.value))
          setGet(tbm*e.target.fa.value) 
          if( e.target.objetivo.value === "perdadegordura") {
            
            setQtdkcal(get-500)
          } else if (e.target.objetivo.value === "hipertrofia"){
            setQtdkcal(get+500)
          }
        }
        
        if(e.target.genero.value == "Feminino"){
          setTbm(665+(9.6*e.target.peso.value)+(1.8*e.target.altura.value)-(4.7*e.target.idade.value))
          setGet(tbm*e.target.fa.value) 
          if( e.target.objetivo.value === "perdadegordura") {
            setQtdkcal(get-500)
            
          } else if (e.target.objetivo.value === "hipertrofia"){
            setQtdkcal(get+500)
          }
        } } */
        function CalculoTBM(e) {
          e.preventDefault();
          setObjetivo(e.target.objetivo.value)
          
          const genero = e.target.genero.value;
          const idade = e.target.idade.value;
          const peso = e.target.peso.value;
          const altura = e.target.altura.value;
          const fa = e.target.fa.value;
          /*  const objetivo = e.target.objetivo.value; */
          
          let calculatedTbm = 0;
          let calculatedGet = 0;
          let calculatedQtdkcal = 0;
          
          /*  ** VALIDADORES INICIO ** */
          
          
          
          /*  VALIDADOR IDADE INICIO */
          if (!e.target.idade.value) {
            setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A idade é obrigatória!</p></div>) 
            return;
          }
          if (e.target.idade.value > 100) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A idade tem que ser menor!</p></div>) 
            return;
          }
          if (e.target.idade.value < 18) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Obrigatório ser maior de 18 anos!</p></div>) 
            return;
          }
          
          if (isNaN(e.target.idade.value)) {
            setAviso(<div><p className='text-danger text-center  mt-2'>A idade tem que ser um número!</p></div>) 
            return;
          }
          /*  VALIDADOR IDADE FIM */
          
          /*  VALIDADOR PESO INICIO */
          if (!e.target.peso.value) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>O peso é obrigatório!</p></div>) 
            return;
          }
          if (e.target.peso.value > 150) {
            setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>Entre em contato através do direct do Instagram e mencione seu peso para analisarmos o seu caso!</p></div>) 
            return;
          }
          if (e.target.peso.value < 30) {
            setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>O peso tem que ser maior!</p></div>) 
            return;
          }
          
          if (isNaN(e.target.peso.value)) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>O peso tem que ser um número inteiro! <br/>Obs: Não use vírgula ou ponto, somente números.</p></div>) 
            return;
          }
          /*  VALIDADOR PESO FIM */
          /*  VALIDADOR ALTURA INICIO */
          if (!e.target.altura.value) {
            setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A altura é obrigatória!</p></div>) 
            return;
          }
          if (e.target.altura.value > 250) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A altura está alta demais!</p></div>) 
            return;
          }
          if (e.target.altura.value <= 80) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A altura tem que ser maior! <br/> Obs: Não use vírgula ou ponto, somente números.<br/> Lembre-se: 1 metro é igual a 100 cm</p></div>) 
            return;
          }
          if (isNaN(e.target.altura.value)) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>A altura tem que ser um número! <br/> Obs: Não use vírgula ou ponto, somente números.<br/> Lembre-se: 1 metro é igual a 100 cm</p></div>) 
            return;
          }
          /*  VALIDADOR ALTURA FIM */
          
          /*  VALIDADOR GENERO INICIO */
          if (e.target.genero.value !== 'Masculino' && e.target.genero.value !== 'Feminino' ) {
            setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>Erro!</p></div>) 
            return;
          }
          if (!e.target.genero.value) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Erro!</p></div>) 
            return;
          }
          /*  VALIDADOR GENERO FIM */
          
          /*  VALIDADOR FA  INICIO */
          if (e.target.fa.value !== '1.375' && e.target.fa.value !== '1.55' && e.target.fa.value !== '1.725' && e.target.fa.value !== '1.9' ) {
            
            setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6 '>Erro!</p></div>) 
            return;
          }
          if (!e.target.fa.value) {
            setAviso(<div><p className='my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Erro!</p></div>) 
            return;
          }
          /*  VALIDADOR FA FIM */
          /*  ** VALIDADORES FIM ** */
          
          
          
          if (genero === "Masculino") {
            calculatedTbm = 66 + 13.7 * peso + 5 * altura - 6.8 * idade;
          } else if (genero === "Feminino") {
            calculatedTbm = 665 + 9.6 * peso + 1.8 * altura - 4.7 * idade;
          }
          
          calculatedGet = calculatedTbm * fa;
          
          if (e.target.objetivo.value === "perdadegordura") {
            /* calculatedQtdkcal = calculatedGet - 500;  ANTES DO TESTE, ERA APENAS ESSA LINHA DENTRO DESSE IF*/
            /*esse cimentario era o padrao ate 05/11/2023 if(calculatedGet - 500 < 2000) {
              calculatedQtdkcal = calculatedGet - 500;
            } else {
              calculatedQtdkcal = calculatedGet - 800;
            } */
/* adicionei 05/11/2023 */
if(calculatedGet <= 2000) {
  calculatedQtdkcal = calculatedGet - 500;
} else if (calculatedGet > 2000 && calculatedGet <= 2200){
  calculatedQtdkcal = calculatedGet - 700;
} else if (calculatedGet > 2200 && calculatedGet <= 2600){
  calculatedQtdkcal = calculatedGet - 800;
} else if (calculatedGet > 2600 && calculatedGet <= 3000){
  calculatedQtdkcal = calculatedGet - 900;
} else if (calculatedGet > 3000 ){
  calculatedQtdkcal = calculatedGet - 1000;
}
/*  */

          } else if (e.target.objetivo.value === "hipertrofia") {
            calculatedQtdkcal = calculatedGet + 500;
          }
          setQtdkcal(calculatedQtdkcal);
          setTbm(calculatedTbm);
          setGet(calculatedGet);
          
        } 
        
        
        
        
        
        return (
          <div className='vh-100 '>
          <div className='d-flex justify-content-center'>
          <div className="p-3 p-sm-4 m-sm-5 /* mt-5 mb-5 */    col-sm-11 col-md-7 col-xl-5 col-xxl-3 bg-white">
          {/*    <p className='fs-4 fw-bold bg-danger text-white text-center p-1'>Formulário </p> */}  
          
          {/* <div className=' bg-white p-5 '> */}
          <span className='fs-3 fw-bold'> Formulário </span>
          {/* <span>Preencha os dados abaixo para descobrir qual o cardápio ideal para você. 
          Após preencher, nós calcularemos o cardápio ideal para o seu caso e você poderá adquiri-lo em seguida. 
        </span> */}<br/>
        <span className='fw-lighter text-secondary'>Dúvidas entre em contato no direct do Instagram.</span>
        <hr/>
        {/* <Form onSubmit={(e) => {CalculoTBM(e); e.preventDefault();}}> */}
        <Form onSubmit={(e) => {CalculoTBM(e); e.preventDefault();}}>
        
        <Form.Group  className="mb-3" controlId="dados">
        <Form.Group className="">
        <Form.Label>Gênero</Form.Label>
        <Form.Select name="genero">
        <option>Feminino</option>
        <option>Masculino</option>
        
        </Form.Select>
        </Form.Group>
        <Form.Label className='mt-3' >Idade</Form.Label>
        <Form.Control type="text" placeholder="Exemplo: 32"  name="idade"   />
        <Form.Label className='mt-3'  >Peso(kg)</Form.Label>
        <Form.Control type="text" placeholder="Exemplo: 70 kg"  name="peso" />
        <Form.Label className='mt-3'  >Altura(cm)</Form.Label>
        <Form.Control type="text" placeholder="Exemplo: 168 cm"  name="altura" />
        <Form.Label className='mt-3'  >Frequência de atividade fisica</Form.Label>
        <Form.Select name="fa">
        <option value="1.375">Leve (Exercício Físico 1-3 dias por semana)</option>
        <option value="1.55">Moderado (Exercício Físico 3-5 dias por semana)</option>
        {/* <option value="1.725">Intenso (Exercício Físico 6-7 dias por semana)</option> 
        <option value="1.9">Extremamente Intenso (Exercício Físico 6-7 dias por semana e trabalho com força física)</option> */}
        <option value="1.725">Intenso (Exercício Físico 6 dias por semana)</option> 
        <option value="1.9">Super Intenso (Exercício Físico 7 dias por semana e trabalho com força física)</option>
        </Form.Select>
        <Form.Label className='mt-3'  >Seu objetivo</Form.Label>
        <Form.Select name="objetivo">
       {/*  <option value="perdadegordura">Perda de Gordura (recomposição corporal)</option>
        <option value="hipertrofia">Hipertrofia (Ganho de massa magra)</option> */}
         <option value="perdadegordura">1) Perda de Gordura e Ganho de Massa Muscular Simultaneamente</option>
        <option value="hipertrofia">2) Foco em Ganho de Massa Muscular(Bulking/Hipertrofia)</option>
        </Form.Select>
        <br/>
        <div className='align-items-center d-flex justify-content-center'>
        <button   className='my-2 p-2 btn btn-danger btn-lg ' type="submit">Quero Meu Cardápio</button>
        </div>
        <div className='align-items-center d-flex justify-content-center'>
        {aviso}
        </div>
        </Form.Group>
        </Form>
        <Outlet />
        </div>
        </div> 
        </div>
        );
      }
      
      export default FormularioCalculadora;