import logo from "./logo.svg";
import ProdutoPersonalizado from "./Administrador/ProdutoPersonalizado";
import {
  Container,
  Navbar,
  NavDropdown,
  Nav,
  Col,
  NavItem,
  Row,
  Card,
  Dropdown,
  DropdownButton,
  Alert,
  Button,
  Table,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import RotaPrivadaAdmin from "./Administrador/RotaPrivadaAdmin";
import AdmLogin from "./AdmLogin";
import Administrador from "./Administrador/Administrador";
import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  redirect,
  Outlet,
  Navigate,
  useNavigate,
  Link,
  Route,
} from "react-router-dom";
/* import { Helmet } from 'react-helmet'; */
import { Helmet, HelmetProvider } from "react-helmet-async";
import Home from "./Home";
import FormularioCalculadora from "./FormularioCalculadora";
import FormularioCalculadoraCicloCarbo from "./FormularioCalculadoraCicloCarbo";
import FormularioCalculadora3CicloCalorias from "./FormularioCalculadora3CicloCalorias";
import Medidas from "./Medidas";
import Vendas from "./Administrador/Vendas";
import Teste from "./Teste";
import FormularioTreino from "./FormularioTreino";
/* import Pdfeditor from './Pdfeditor'; */

function ComponentePai() {
  return (
    <div>
      <RotaPrivadaAdmin />
      <Administrador />
    </div>
  );
}
function RedirecionarSite() {
  window.location.href = "https://darlanfurtado.com/";
  return;
}

function Header() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Furtado Team</title>

        <link rel="icon" href="./public/icon.png" />
        <meta name="description" content="Furtado Team" />
        <meta property="og:title" content="Furtado Team" />
        <meta property="og:description" content="Furtado Team" />
        <meta property="og:image" content="" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="https://admin.darlanfurtado.com.br/" />
      </Helmet>
    </HelmetProvider>
  );
}

function App() {
  /*  const uniqueKey = new Date().getTime(); */
  {
    /* <Route path={`/cardapios-calculadora-2?v=${uniqueKey}`} element={<FormularioCalculadoraCicloCarbo />} ></Route> */
  }

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        // ROTAS ABERTA
        <Route path="/" element={<Home />}></Route>
        <Route path="/calculadora" element={<FormularioCalculadora />}></Route>
        <Route
          path="/calculadora-cardapios"
          element={<FormularioCalculadora />}
        ></Route>
        <Route
          path="/calculadora-cardapios-fteam"
          element={<FormularioCalculadora />}
        ></Route>
        <Route
          path="/calculadora-cardapios-fteam-3"
          element={<FormularioCalculadora />}
        ></Route>
         <Route
          path="/calculadora-cardapios-fteam-4"
          element={<FormularioCalculadora />}
        ></Route>
        <Route
          path="/calculadora-cardapios-fteam-5"
          element={<FormularioCalculadora />}
        ></Route>
        <Route
          path="/calculadora-cardapios-etapa-2"
          element={<FormularioCalculadoraCicloCarbo />}
        ></Route>
        <Route
          path="/calculadora-cardapios-etapa-3"
          element={<FormularioCalculadora3CicloCalorias />}
        ></Route>
        
        <Route
          path="/cardapios-calculadora-2"
          element={<FormularioCalculadoraCicloCarbo />}
        ></Route>
        
        
        <Route
          path="/formulario-treino"
          element={<FormularioTreino />}
        ></Route>


        {/* <Route path="/medidas" element={<Medidas />} ></Route>  */}
        {/*  <Route path="/teste" element={<Teste/>} /> */}{" "}
        {/* teste do sidebarmenu novo */}
        {/*   <Route path="/" element={<AdmLogin />} ></Route> */}
        {/*  <Route path="/" element={<RedirecionarSite />}>  </Route> */}
        <Route path="/admlogin" element={<AdmLogin />}></Route>
        // ROTAS ADMINISTRADOR
        <Route path="administrador/*" element={<ComponentePai />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
