
import {Container,Navbar,NavDropdown,Nav, Col,NavItem,Row, Card, Dropdown,DropdownButton, Alert, Button   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuAdministrador from './MenuAdministrador';
import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
/* import ProdutosAdministrador from './ProdutosAdministrador'; */
import Sidebar from './SidebarAdministrador';
/* import CriarProdutos from './CriarProdutos'; */
import 'bootstrap-icons/font/bootstrap-icons.css';
/* import AdicionarProdutos from './AdicionarProdutos'; */
import { Deslogar } from './Administrador';


// MENU LATERAL
function SidebarAdministrador () {
  return (
    <div className='  mt-5 position-fixed '  >
    <div className='mt-5'></div>
    
    <Nav  className='d-block' variant="pills"   >
    <p className='text-white-50 mt-4 fw-bold'><i className="bi bi-list-ul mx-2"></i>Menu</p>
   
    {/* <Nav.Item>
    <Nav.Link  href="/administrador/dashboard/" className='text-light '><i className="bi-dash mx-2"></i>Painel Geral</Nav.Link>
    </Nav.Item>
    <NavDropdown.Divider /> */}
    {/* <Nav.Item >
    <span className='text-white-50  fw-bold'><i className="bi bi-card-checklist mx-2"></i>Produtos</span>
    
    <Nav.Link  href="/administrador/dashboard/produtos" className='text-light'><i className="bi bi-dash mx-2"></i>Editar produtos</Nav.Link>
    <Nav.Link  href="/administrador/dashboard/criar-produto" className='text-light'><i className="bi bi-dash mx-2"></i>Criar Produto</Nav.Link>
 
    
    
    </Nav.Item> */}
    
{/*     <NavDropdown.Divider />
    <Nav.Item>
    <span className='text-white-50 mt-4 fw-bold'><i className="bi bi-cart-dash mx-2"></i>Vendas</span>
    <Nav.Link  href="/administrador/dashboard/vendas" className='text-light'><i className="bi bi-dash mx-2"></i>Visão geral</Nav.Link>
    <Nav.Link  href="/administrador/dashboard/reembolsos" className='text-light'><i className="bi bi-dash mx-2"></i>Reembolsos</Nav.Link>
    <Nav.Link  href="/administrador/dashboard/clientes" className='text-light'><i className="bi bi-dash mx-2"></i>Clientes</Nav.Link>
    
    </Nav.Item> <NavDropdown.Divider />
    <Nav.Item>
    <span className='text-white-50 mt-4 fw-bold'><i className="bi bi-graph-up-arrow mx-2"></i>Dados</span>
    <Nav.Link href="#" className='' disabled><i className="bi bi-dash mx-2"></i>Relatórios</Nav.Link>
    <Nav.Link href="#" className='' disabled><i className="bi bi-dash mx-2"></i>Despesas</Nav.Link>
    <Nav.Link href="#" className='' disabled><i className="bi bi-dash mx-2"></i>Lucro</Nav.Link>
    </Nav.Item>
    <NavDropdown.Divider /> */}
    <Nav.Item>{/* 
    <span className='text-white-50 mt-4 fw-bold'><i className="bi bi-graph-up-arrow mx-2"></i>Ferramentas</span> */}
    {/* <Nav.Link href="./produto-personalizado" className='text-white'><i className="bi bi-dash mx-2"></i>Personalizada 2.0</Nav.Link>
    <Nav.Link href="./pdfeditor" className='text-white'><i className="bi bi-dash mx-2 "></i>Personalizada 1.0</Nav.Link>
    <Nav.Link href="./dieta" className='text-white'><i className="bi bi-dash mx-2 "></i>Criadora<br/>de refeições</Nav.Link>
    <Nav.Link href="./formulario" className='text-white'><i className="bi bi-dash mx-2 "></i>Calculadora<br/>de calorias</Nav.Link>
    */}
     <Link  className="text-white btn" to="./produto-personalizado"><i className="bi bi-dash mx-2 "></i>Produto<br/>Personalizado 2.0</Link> 
     <hr/>
    <Link  className="text-white btn" to="./pdfeditor"><i className="bi bi-dash mx-2 "></i>Produto<br/>Personalizado 1.0</Link> 
    <hr/>
    <Link  className="text-white btn" to="./dieta"><i className="bi bi-dash mx-2 "></i>Calculadora<br/>de refeições</Link>
    <hr/>
    <Link  className="text-white btn" to="./formulario"><i className="bi bi-dash mx-2 "></i>Calculadora<br/>de calorias</Link>
    <hr/>
    <Link  className="text-white btn" to="./vendas"><i className="bi bi-dash mx-2 "></i>Vendas</Link>
    
    {/* <Nav.Link href="/administrador/dashboard/listanegra" className='text-white'><i className="bi bi-dash mx-2"></i>Lista Negra</Nav.Link>
 */}
    </Nav.Item>
    <NavDropdown.Divider />
    
    
    
    <div className='shadow fixed-bottom p-4 text-center ' style={{"width": "200px"}}>
    
    {/* <Card>
    <Card.Body>
    <span className="   badge bg-secondary">Administrador</span><br/>
    <span className='fs-6 '>Fulano Ciclano</span>
    
    </Card.Body>
    
    </Card> */}
    <button   className='mt-5  btn btn-secondary'  onClick={(e) => {Deslogar(); window.location.reload();}} >Deslogar</button>

    </div> 
    
    </Nav>
    
    
    
    </div>
    
    
    
    );
    
  }
  
  export default SidebarAdministrador;