import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { Login } from './Administrador/Administrador';
import { Routes, Route, Link,BrowserRouter, Outlet,Navigate, useNavigate,    } from "react-router-dom";
import RotaPrivadaAdmin from './Administrador/RotaPrivadaAdmin';




function AdmLogin() {
  const [red, setRed] = useState(null);
  const [aviso, setAviso] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

 
  
  
  function Logar(e){

    // inicio VALIDADORES
    if (!e.target.email.value) {
      setAviso(<div><p className='text-danger text-center fw-bold mt-2'>O email é obrigatório!</p></div>) 
      return;
    }
    var email = e.target.email.value;
    var tamanhoemail = email.length;
    if (tamanhoemail < 7 || tamanhoemail > 200 ) {
      setAviso(<div><p className='text-danger text-center fw-bold mt-2'>Digite o email corretamente</p></div>) 
      return;
    }
    if (!e.target.password.value) {
      setAviso(<div><p className='text-danger text-center fw-bold mt-2'>A senha é obrigatória!</p></div>) 
      return;
    }
    function CredenciaisIncorretas() {
      setAviso(<div><p className='text-danger text-center fw-bold mt-2'>Seu email ou senha estão incorretos!</p></div>) 
      return;   
    }
    // FIM VALIDADORES
    var email = e.target.email.value
    var password = e.target.password.value
/*     axios.post('https://api.furtadoteam.com/auth', { email, password })
 */   /* axios.post('http://gkwssoc048k4oos84wgwsc84.207.244.225.181.sslip.io:3002/auth', { email, password })
   */  axios.post('https://api.furtadoteam.com.br/auth', { email, password })
     .then(response => {
      localStorage.setItem('token', response.data.token);
      console.log('Usuário logado com sucesso.');
      /* console.log(response.data.token); */
      setAviso(<div><p className='text-danger text-center fw-bold mt-2'>Logado com sucesso!</p></div>)
      //  APOS O LOGIN REDIRECIONAR PARA PERSONALIZADA
      setRed( <Navigate to='/administrador/produto-personalizado'  replace />)
    })
    .catch(error => {
      console.log(error);
      CredenciaisIncorretas()
    });

  }
  return (
    
    <div className='vh-100 bg-light'>
    <div className='d-flex justify-content-center'>
    <div className=" m-sm-5 mt-5 mb-5 shadow   col-sm-11 col-md-7 col-xl-5 col-xxl-3 bg-white">
    <p className='fs-3 fw-bold bg-danger text-white text-center p-1'>Furtado Team</p>    
    <p className='fs-5   text-muted text-center p-1'>Preencha os campos abaixo para acessar a plataforma</p>  
    {aviso}
    <Form className='p-3 p-sm-4' onSubmit={(e) => {Logar(e); e.preventDefault();}}>
    <Form.Group  className="mb-3 " controlId="formBasicEmail">
    <Form.Label column  className='fw-bold'>Endereço de email</Form.Label>
    <Form.Control className='bg-light border border-white' type="email" name="email" placeholder="Insira seu email"   required />
    </Form.Group>
    
    <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label  className='fw-bold'>Senha</Form.Label>
    <Form.Control type="password" className='bg-light border border-white' name="password" placeholder="Insira sua senha"   required />
    </Form.Group>
    
    <Button className='mt-4 p-1  col-6 mx-auto btn btn-primary  d-flex justify-content-center' variant="primary" type="submit">
    Entrar
    </Button>
    </Form>
    </div>
    
    </div>
    
    {red}
    
    
    </div>
    
    );
  }
  
  
  export default AdmLogin;
  
