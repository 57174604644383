import {Container,Navbar,NavDropdown,Nav,Tooltip, Modal, Badge,Col,NavItem,Row, Table,Card,Form, Dropdown,DropdownButton, Alert, Button   } from 'react-bootstrap';

import { PDFDocument, StandardFonts, rgb, PDFLib,degrees } from 'pdf-lib'
import download from 'downloadjs';
import listaProdutos from './ListaProdutos';
import mc1 from "./planilhas/Treino para Mulheres - Continuidade Etapa 1.pdf";
import {modifypdf} from "./ProdutoPersonalizado"

import 'bootstrap/dist/css/bootstrap.min.css';
import { render } from "react-dom";
import moment from 'moment';
import {
  BrowserRouter,
  Routes,Link,
  Route,    
  Outlet,
  useOutletContext
} from "react-router-dom";

import React, { useState, useEffect  } from 'react';
/* 
const axios = require('axios'); */
import axios from 'axios';
import SidebarMenu from './SidebarMenu';




/*  */

function Vendas() {
  const [emailInput, setEmailInput] = useState('');
  const [aviso, setAviso] = useState('');
  const [email, setEmail] = useState('');
  const defaultStartDate = moment().format('YYYY-MM-DD'); // Data atual como valor inicial no formato "aaaa-mm-dd"
  
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultStartDate);
  const [transactions, setTransactions] = useState([]);
  
  
  /* AVISO LOGICA */
  function ContadorBadge() {
    setTimeout(function() {
      setAviso("");
    }, 3300); 
    return '';
  }
  function AvisoBadge(){
    if (aviso != "") {
      return (
        <div>
        <div  style={{ position: 'relative' }}>
        <Alert  variant="danger" className='shadow ' style={{ position: 'fixed', top: 90, right: 20 }}>
        {aviso}</Alert></div>
        <ContadorBadge />
        </div>
        )
      } else {
        return ''
      }
    }
    /* AVISO LOGICA */
    
    useEffect(() => {
      const timer = setTimeout(() => {
        setAviso('');
      }, 6000);
      
      return () => clearTimeout(timer);
    }, [aviso]);
    
    
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      try {
        
        const response = await axios.post('https://www.darlanfurtado.com.br:3000/transactions', { email, startDate, endDate });
        
        if (response.data.success) {
          setTransactions(response.data.transactions);
          console.log(response.data.transactions)
          if(response.data.transactions.length === 0)
          {
            setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Não foi encontrado nenhuma transação com esses filtros!</p></div>)
            
          }
        } else {
          setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Falha na requisição!</p></div>)
          console.error('Failed to fetch transactions');
        }
      } catch (error) {
        setAviso(<div><p className=' my-2 text-danger shadow shadow-sm p-2 bg-light  border-rounded bg-bold fs-6'>Algum erro aconteceu!</p></div>)
        console.error('Error:', error);
      }
    };
    
    /* EnviarPersonalizada */
    function EnviarPersonalizada (e) {
      const option = JSON.parse(e.target.produto.value);
      console.log(e.target.email.value)
      console.log(e.target.nome.value)
      console.log(e.target.cpf.value)
      console.log(option.nome) 
      const config = {
        headers: { Authorization: `Bearer ` + localStorage.getItem('token') }
      };
      axios.get('https://www.darlanfurtado.com.br:3000/send-email/' + e.target.email.value + '/' + e.target.nome.value + '/' + e.target.cpf.value + '/' + option.nome, config)
      .then(function (response) {
        // handle success
        console.log("Email enviado com Sucesso!")
        setAviso(<span>Email enviado com Sucesso!!</span>)
      })
      .catch(function (error) {
        // handle error
        console.log("Email não enviado, erro!")
        setAviso(<p>Algum erro aconteceu, atualize a pagina!</p>)
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    }
    /* EnviarPersonalizada */
    
    
    /* MODAL  */
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const abrirModal = () => {
      setShowModal(true);
    };
    
    const fecharModal = () => {
      setShowModal(false);
    };
    
    const abrirModal2 = () => {
      setShowModal2(true);
    };
    
    const fecharModal2 = () => {
      setShowModal2(false);
    };
    const abrirModal3 = () => {
      setShowModal3(true);
    };
    
    const fecharModal3 = () => {
      setShowModal3(false);
    };
    /* MODAL END */
    const formatCPF = (cpf) => {
      if (cpf && cpf.length === 11) {
        return (
          cpf.slice(0, 3) +
          '.' +
          cpf.slice(3, 6) +
          '.' +
          cpf.slice(6, 9) +
          '-' +
          cpf.slice(9)
          );
        }
        return cpf; // Return the original value if it's not a valid CPF
      };
      
      
      const encontrarLinkDoProduto = (nomeProduto) => {
        const produtoEncontrado = listaProdutos.find((produto) => produto.nome === nomeProduto);
        return produtoEncontrado ? produtoEncontrado.link : '';
      };
      
      
      
      return (
        <div className='mt-5 container'>
        
        <div className='bg-white  rounded '>
        <form onSubmit={handleSubmit} className='  px-4 pt-4 rounded align-center  '> 
        <h5 className='fs-3 fw-bold'> Consultar transações </h5>
        
        <span className='fw-lighter text-secondary'>Preencha os campos abaixo para identificar as transações.</span>
        <hr/>
        {/* data inicial e final - inicio */} 
        <div className='row  mt-3'>
        <div className="form-group   col-6 col-sm-4 col-md-3 col-lg-3 col-xxl-2 mb-2">
        <label htmlFor="startDateInput">Data inicial:</label>
        <input
        type="date"
        className="form-control"
        id="startDateInput"
        value={startDate}
        
        onChange={(e) => setStartDate(e.target.value)}
        />
        </div>
        <div className="form-group col-6 col-sm-4 col-md-3 col-lg-3 col-xxl-2 mb-2">
        <label htmlFor="endDateInput">Data final:</label>
        <input
        type="date"
        className="form-control"
        id="endDateInput"
        value={endDate}
        
        onChange={(e) => setEndDate(e.target.value)}
        />
        </div>
        
        <div className="form-group  col-12 col-sm-4 col-md-6 col-lg-6 col-xxl-3">
        <label htmlFor="emailInput" className=' '>Email do cliente:</label>
        <input
        type="email"
        className="form-control"
        id="emailInput"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        />
        </div>
        </div>
        {/* data inicial e final - final  */}
        
        
        
        <button type="submit" className="btn btn-primary my-3">
        Consultar sistema
        </button>
        {aviso}
        </form>
        {/* MODAL */}
        <div className=' '>
        <hr/>
        <Button variant="white" onClick={abrirModal} className='text-secondary mb-3'>
        Clique aqui para ver algumas orientações e informações importantes.
        </Button>
        
        <Modal show={showModal} onHide={fecharModal} size="lg" centered>
        <Modal.Header closeButton>
        <Modal.Title>Informações</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>1) Se você deseja ver todo histórico de compras de um cliente, aumente o período analisado.</p>
        <p>2) Se o cliente digitou o email errado e não soube te informar o erro, você não conseguirá identificar. Veja se encontra na lista das compras selecionando apenas o dia que ele comprou, sem mencionar o email. Ou solicite ajuda.</p>
        <p>3) O email não é obrigatório. Você pode analisar as vendas dentro do período selecionado</p>
        {/* Adicione as informações adicionais que você deseja mostrar */}
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={fecharModal}>
        Fechar
        </Button>
        </Modal.Footer>
        </Modal>
        </div>
        {/* MODAL END*/}
        </div>
        
        
        {/* {transactions && transactions.length > 0 ? (
          <div>
          <h2>Transactions:</h2>
          <ul>
          {transactions.map((transaction) => (
            <li key={transaction.sale_id}>
            {transaction.client_name},
            {transaction.sale_status_name},
            {transaction.date_create},
            {transaction.sale_payment_method},
            {transaction.content_title},
            {transaction.client_document},
            {transaction.client_email},
            </li>
            
            ))}
            </ul>
            </div>
            ) : (
              <p>No transactions found</p>
            )} */}
            
            {/* {transactions && transactions.length > 0 ? (
              <div>
              <h2>Transactions:</h2>
              <Table responsive>
              <thead>
              <tr>
              <th>Client Name</th>
              <th>Sale Status</th>
              <th>Date Create</th>
              <th>Payment Method</th>
              <th>Content Title</th>
              <th>Client Document</th>
              <th>Client Email</th>
              </tr>
              </thead>
              <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.sale_id}>
                <td>{transaction.client_name}</td>
                <td>{transaction.sale_status_name}</td>
                <td>{transaction.date_create}</td>
                <td>{transaction.sale_payment_method}</td>
                <td>{transaction.content_title}</td>
                <td>{transaction.client_document}</td>
                <td>{transaction.client_email}</td>
                </tr>
                ))}
                </tbody>
                </Table>
                </div>
                ) : (
                  <p>No transactions found</p>
                )} */}
                
                {/* <h3 className='text-center mt-3'>Transações</h3> */}
                {transactions && transactions.length > 0 ? (
                  <div className='   rounded mt-4 pt-1    '>
                  
                  
                  <Table responsive hover striped className='rounded bg-white text-center align-middle '>
                  <thead>
                  <tr className='bg-white text-center align-middle '>
                  
                  <th>Cliente</th>
                  <th>Status da compra<br/>e data</th>
                  {/*  <th>Data</th> */}
                  {/*  <th>Método de pagamento</th> */}
                  <th>Produto</th>
                  <th>Ações</th>
                  </tr>
                  </thead>
                  <tbody>
                  {transactions.map((transaction) => (
                    <tr key={transaction.sale_id}>
                    
                    <td>
                    <strong>{transaction.client_name}</strong><br />
                    {transaction.client_email}<br />
                    <i>CPF: </i>{/* {transaction.client_document} */} {formatCPF(transaction.client_document)}<br />
                    <i>Pagamento: </i>{transaction.sale_payment_method}
                    </td>
                    
                    <td>
                    <Badge bg={
                      transaction.sale_status_name === "Paga" ? "primary" :
                      transaction.sale_status_name === "Aberta" ? "success" :
                      transaction.sale_status_name === "Aguardando Reembolso" ? "danger" :
                      transaction.sale_status_name === "Reembolsado" ? "danger" :
                      "secondary"
                    }>{transaction.sale_status_name}</Badge>
                    <br/>
                    {moment(transaction.date_create).format('DD/MM/YYYY')}
                    <br />
                    {moment(transaction.date_create).format('HH:mm')}
                    </td>
                    {/*  <td>{transaction.sale_payment_method}</td> */}
                    <td>{transaction.content_title}</td>
                    <td>
                    {/* Conditionally render the buttons */}
                    {transaction.sale_status_name === "Paga" && (
                      <>
                      {/*   <Button variant="" className='border col-12 '>Personalizada</Button>
                    <Button variant="" className='border col-12 my-2'>Email diferente</Button> */}
                    <div className='' />
                    
                    
                    
                    <Dropdown>
                    <Dropdown.Toggle variant="light" className='border' id="dropdown-basic">
                    {/* Ícone de três pontinhos */}
                    <span>&#8942;</span>
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu>
                    {/* Opções do menu */}
                    {/* PRIMEIRA OPÇÃO NO MENU - Enviar personalizada por email automaticamente com os dados cadastrados pelo cliente - FUNÇÃO AQUI NESSE ARQUIVO */}
                    <Dropdown.Item href="#option1"> 
                    <Form onSubmit={(e) => {EnviarPersonalizada(e); e.preventDefault();}}>
                    <Form.Control
                    name="nome"
                    type="hidden"
                    value={transaction.client_name}
                    />
                    <Form.Control
                    name="cpf"
                    type="hidden"
                    value={formatCPF(transaction.client_document)}
                    />  
                    <Form.Control
                    name="email"
                    type="hidden"
                     value={transaction.client_email} 
                    /* value="ricardoaguiar069@gmail.com"   */
                    />
                    {/*  <Form.Control
                    name="produto"
                    type="hidden"
                    value={encontrarLinkDoProduto(transaction.content_title)}
                  /> */}
                  <Form.Control
                  name="produto"
                  type="hidden"
                  value={JSON.stringify({
                    value: encontrarLinkDoProduto(transaction.content_title),
                    nome: transaction.content_title,
                  })}
                  />
                  
                  <button variant=""  className='col-12 btn border-top  border-bottom'>Enviar Personalizada</button>
                  
                  </Form>
                  
                  
                  
                  </Dropdown.Item>
                  {/* SEGUNDA OPÇÃO NO MENU - FAZER DOWNLOAD */}
                  <Dropdown.Item href="#option2"> 
                  {/* INICIO - BOTÃO DOWNLOAD -  BUSCA A FUNÇÃO NO ARQUIVO PRODUTOSPERSONALIZADOS PARA REALIZAR O DOWNLOAD DO PRODUTO */}
                  <Form onSubmit={(e) => {modifypdf(e); e.preventDefault();}}>
                  <Form.Control
                  name="nome"
                  type="hidden"
                  
                  value={transaction.client_name}
                  />
                  
                  <Form.Control
                  name="cpf"
                  type="hidden"
                  
                  value={formatCPF(transaction.client_document)}
                  />  
                  <Form.Control
                  name="email"
                  type="hidden"
                  
                  value={transaction.client_email}
                  />
                  {/*  <Form.Control
                  name="produto"
                  type="hidden"
                  value={encontrarLinkDoProduto(transaction.content_title)}
                /> */}
                <Form.Control
                name="produto"
                type="hidden"
                value={JSON.stringify({
                  value: encontrarLinkDoProduto(transaction.content_title),
                  nome: transaction.content_title,
                })}
                />
                {/*   <button variant=""  className='col-12 btn border'>Download</button> */}
                <button variant=""  className='col-12 btn border-top  border-bottom'>Fazer download</button>
                
                </Form>
                {/* FIM - BOTÃO DOWNLOAD - BUSCA A FUNÇÃO NO PRODUTOSPERSONALIZADOS PARA REALIZAR O DOWNLOAD DO PRODUTO */}
                </Dropdown.Item>
                {/* OPCAO 3- INICIO BOTAO ENVIAR PARA OUTRO EMAIL  */}
                <Dropdown.Item href="#option3">
                
                <button  onClick={abrirModal2} className='col-12 btn border-top  border-bottom '>
                Enviar para outro email
                </button>
                <Modal show={showModal2} onHide={fecharModal2} size="lg" centered>
                <Modal.Header closeButton>
                <Modal.Title>Enviar para outro email</Modal.Title>
                
                </Modal.Header>
                
                <Modal.Body>
                
                <span className='text-muted'> Essa funcionalidade serve para o cliente que digitou o email errado no momento da compra ou que não está recebendo com o email cadastrado e quer tentar um email diferente.</span>
                <br/> <hr/>
                <b>Abaixo estão os dados cadastrados pelo cliente no momento da compra:</b><br/>
                Nome: {transaction.client_name} <br/>
                E-mail: <span className='text-danger'>{transaction.client_email}</span><br/>
                CPF: {formatCPF(transaction.client_document)}<br/>
                Produto: {transaction.content_title}<br/>
                <i className="bi bi-arrow-down d-flex justify-content-center text-primary"></i>
                <Form className='my-2' onSubmit={(e) => {EnviarPersonalizada(e); e.preventDefault();}}>{/* ADICIONAR NESSE FORM O CAMPO EMAIL A SER INSERIDO */}
                <Form.Control
                name="nome"
                type="hidden"
                value={transaction.client_name}
                />
                <Form.Control
                name="cpf"
                type="hidden"
                value={formatCPF(transaction.client_document)}
                />  
                <Form.Control
                name="produto"
                type="hidden"
                value={JSON.stringify({
                  value: encontrarLinkDoProduto(transaction.content_title),
                  nome: transaction.content_title,
                })}
                />
                
                <Form.Label>Insira abaixo o email que o produto deve ser enviado:</Form.Label>
                <Form.Control
                type="email"
                name="email"
                placeholder="Digite o email"
                /* value="ricardoaguiar069@gmail.com" */
                value={emailInput} 
                onChange={(e) => setEmailInput(e.target.value)} 
                />
                
                <button type="submit" className="btn btn-danger my-3 ">
                Confirmar envio
                </button>
                <hr/>
                <Form.Label className='text-muted'>Confira os dados antes de enviar! Uma vez enviado o sistema não tem como reverter o email. </Form.Label>
                <Form.Label className='text-muted'>O sistema criará o produto PDF e enviará para o email inserido acima com a marca d'água contendo o Nome e CPF cadastrados pelo cliente e o Email que você inseriu acima. </Form.Label>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={fecharModal2}>
                Fechar
                </Button>
                </Modal.Footer>
                </Modal>
                </Dropdown.Item>
                {/* OPCAO 3- FIM BOTAO ENVIAR PARA OUTRO EMAIL  */}
                
                {/* OPCAO 4- INCIIO BOTAO ENVIAR OUTRO PRODUTO  */}
                <Dropdown.Item href="#option4">
                
                <button  onClick={abrirModal3} className='col-12 btn border-top  border-bottom '>
                Enviar outro produto para esse cliente
                </button>
                <Modal show={showModal3} onHide={fecharModal3} size="lg" centered>
                <Modal.Header closeButton>
                <Modal.Title>Enviar outro produto para esse cliente</Modal.Title>
                
                </Modal.Header>
                
                <Modal.Body>
                
                <span className='text-muted'> Essa funcionalidade serve para o cliente que comprou o produto errado.</span>
                <br/> <hr/>
                <b>Abaixo estão os dados cadastrados pelo cliente no momento da compra:</b><br/>
                Nome: {transaction.client_name} <br/>
                E-mail: <span className='text-primary'>{transaction.client_email}</span><br/>
                CPF: {formatCPF(transaction.client_document)}<br/>
                Produto comprado: <span className='text-success'>{transaction.content_title}</span><br/>
                <i className="bi bi-arrow-down d-flex justify-content-center text-primary"></i>
                
                <Form className='my-2' onSubmit={(e) => {EnviarPersonalizada(e); e.preventDefault();}}>
                <Form.Control
                name="nome"
                type="hidden"
                value={transaction.client_name}
                />
                <Form.Control
                name="cpf"
                type="hidden"
                value={formatCPF(transaction.client_document)}
                />  
                <Form.Control
                name="email"
                type="hidden"
                 value={transaction.client_email} 
                /* value="ricardoaguiar069@gmail.com"  */ 
                />
                
                <Form.Label className='mt-3'  >Selecione o produto a ser enviado para esse cliente</Form.Label>
                <Form.Select name="produto" className='mt-2'  >
                <option>Selecione o produto</option>   
                {listaProdutos.map((item, index) => 
                  <option value={JSON.stringify({value :item.link,nome: item.nome})} key={item.link}>{item.nome}</option>    
                  ) }
                  </Form.Select>
                  
                  <button type="submit" className="btn btn-danger my-3 ">
                  Confirmar envio
                  </button>
                  <hr/>
                  </Form>
                  
                  
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="secondary" onClick={fecharModal3}>
                  Fechar
                  </Button>
                  </Modal.Footer>
                  </Modal>
                  
                  </Dropdown.Item>
                  {/* OPCAO 4- FIM BOTAO ENVIAR OUTRO PRODUTO  */}
                  </Dropdown.Menu>
                  </Dropdown>
                  
                  
                  
                  {/*    <Button onClick={() => modifypdf({ 
                    nome: 'Nome do Usuário',
                    email: 'usuario@example.com',
                    cpf: '123.456.789-00',
                    produto: 'Treino para Homens - Continuidade Etapa 3'
                  })}>Enviar Dados</Button> */}
                  </>
                  )}
                  </td>
                  </tr>
                  ))}
                  </tbody>
                  </Table>
                  
                  </div>
                  ) : (
                    <p></p>
                    )}
                    
                    
                    
                    
                    
                    <AvisoBadge />
                    </div>
                    );
                  }
                  
                  export default Vendas;