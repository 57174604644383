import logo from './logo.svg';
import ProdutoPersonalizado from './Administrador/ProdutoPersonalizado'
import {Container,Navbar,NavDropdown,Nav, Col,NavItem,Row, Card, Dropdown,DropdownButton, Alert, Button, Table, Form   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import RotaPrivadaAdmin from './Administrador/RotaPrivadaAdmin';
import AdmLogin from './AdmLogin';
import Administrador from './Administrador/Administrador';
import { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  redirect ,
  Outlet,
  Navigate,useNavigate, Link, 
  Route
} from "react-router-dom";
import { Helmet } from 'react-helmet';


function Home() {
  
    useEffect(() => {
        redirecionarParaLink();
      }, []);
    
      const redirecionarParaLink = () => {
        // Lógica para redirecionar para o link desejado
        window.location.href = 'https://darlanfurtado.com/';
      };
    
  return (<div></div>);
}

export default Home;


