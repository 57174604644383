import {Container,Navbar,Image, NavDropdown,Nav,Tooltip, Col,NavItem,Row, Card,Form, Dropdown,DropdownButton, Alert, Button   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { render } from "react-dom";
import {
    BrowserRouter,
    Routes,
    Route,    
    Outlet,
    useOutletContext
} from "react-router-dom";

import React, { useState, useEffect } from 'react';
import balanca from './images/balanca.png';
import bracomedial from './images/bracomedial.png';
import cintura from './images/cintura.png';
import coxamedial from './images/coxamedial.png';
import fitametrica from './images/fitametrica.png';

const axios = require('axios');


function Medidas() {
   
    

    
    return (
            <div className='vh-100 bg-light'>
            <div className='d-flex justify-content-center'>
            <div className="p-3 p-sm-4 m-sm-5 mt-5 mb-5 shadow   col-sm-11 col-md-7 col-xl-5 col-xxl-3 bg-white">
       
        <p className='fs-3 fw-bold text-center'> Orientações  </p>
        <p className='text-center'>Material que você vai precisar:<br/> Fita Métrica Comum e Balança de Banheiro.</p>
        <hr/> 
   
<p className='text-center'>Voce deverá aferir a seguintes medidas:</p>
<br/>
<p className='text-center fw-bold'>1) Coxa medial</p>
<div className="d-flex justify-content-center align-items-center ">
      <Image src={coxamedial} alt="coxamedial" className='shadow-sm w-50 rounded'/>
</div>
<hr/>
<p className='text-center fw-bold'>2) Braço medial</p>
<div className="d-flex justify-content-center align-items-center ">
      <Image src={bracomedial} alt="bracomedial" className='shadow-sm w-50 rounded'/>
</div>
<hr/>
<p className='text-center fw-bold'>3) Cintura</p>
<div className="d-flex justify-content-center align-items-center ">
      <Image src={cintura} alt="cintura" className='shadow-sm w-50 rounded'/>
</div>
<hr/>
<p className='text-center fw-bold'>4) Peso em jejum</p>
<p className='text-center'>A melhor hora para se pesar é logo depois de acordar, pois assim, é fácil manter o padrão de medida, evitando as alterações no organismo causadas ingestão de liquidos e alimentos sólidos.</p>


        

        <Outlet />
        </div> </div> </div>
        );
    }
    
    export default Medidas;