import { PDFDocument, StandardFonts, rgb, PDFLib,degrees } from 'pdf-lib'
import download from 'downloadjs';
import {Container,Navbar,NavDropdown,Nav, Col,NavItem,Row, Card, Dropdown,DropdownButton, Alert, Button, Table   } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import * as React from "react";
import { PureComponent } from 'react';

  
    
    function Painel () {
        
        
        
        return (
            <Row>
            
           {/*  <Col className='col-9' xs={12} sm={7}>
            <div className='bg-white p-4 mt-4 shadow rounded'   >
            <span className='fs-2'>Gráfico de Receitas</span><br/>
            <span className=''>Últimos 7 dias</span>
            <div className="mt-4" style={{ width: "100%", height: 300 }}>
          
            </div>
            <div className=''>
            
            <Row className='mt-5 d-flex justify-content-center' >
            <Col className='p-3 m-2 bg-light'  lg={12} xl={3}><span>Vendas</span><br/>
            <p className='fs-5 text-success fw-bold fs-5'>R$ 195,000.00</p></Col>
            <Col className='p-3 m-2 bg-light' lg={12} xl={3}><span>Reembolsos</span>
            <p className='fs-5 text-success fw-bold fs-5'>R$ 1,350.00</p></Col>
            <Col className='p-3 m-2 bg-light'  lg={12} xl={3}><span>% Reembolso</span>
            <p className='fs-5 text-success fw-bold fs-5'>1.4%</p></Col>
            
            </Row>
            </div>
            
            </div>
            </Col>
            
            
            <Col className='col-3  ' xs={12} sm={4}>
            <div className='bg-white p-4 mt-4 shadow rounded'   >
            <span className='fs-2  '>Lorem Ipsum</span>
            <hr/>
            <span>Ganho nos últimos 7 dias</span>
            <p className='fs-3 text-success fw-bold'>R$ 195,000.00</p>
            
            <hr/>
            <span>Ganho nos últimos 30 dias</span>
            <p className='fs-3 text-success fw-bold'>R$ 395,000.00</p>
            <hr/>
            <span>Ganho nos últimos 90 dias</span>
            <p className='fs-3 text-success fw-bold'>R$ 1.295,000.00</p>
            </div>
         
            
            </Col> */}
            </Row>
            
            );
        }
        
        export default Painel;