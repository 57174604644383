import mc1 from "./planilhas/Treino para Mulheres - Continuidade Etapa 1.pdf";
import mc2 from "./planilhas/Treino para Mulheres - Continuidade Etapa 2.pdf";
import mc3 from "./planilhas/Treino para Mulheres - Continuidade Etapa 3.pdf";
import mc4 from "./planilhas/Treino para Mulheres - Continuidade Etapa 4.pdf";
import mc5 from "./planilhas/Treino para Mulheres - Continuidade Etapa 5.pdf";
import mc6 from "./planilhas/Treino para Mulheres - Continuidade Etapa 6.pdf";
import mc7 from "./planilhas/Treino para Mulheres - Continuidade Etapa 7.pdf";
import mc8 from "./planilhas/Treino para Mulheres - Continuidade Etapa 8.pdf";
import mc9 from "./planilhas/Treino para Mulheres - Continuidade Etapa 9.pdf";
import mc10 from "./planilhas/Treino para Mulheres - Continuidade Etapa 10.pdf";
import mc11 from "./planilhas/Treino para Mulheres - Continuidade Etapa 11.pdf";
import mc12 from "./planilhas/Treino para Mulheres - Continuidade Etapa 12.pdf";
import mc13 from "./planilhas/Treino para Mulheres - Continuidade Etapa 13.pdf";
import mc14 from "./planilhas/Treino para Mulheres - Continuidade Etapa 14.pdf";
import mc15 from "./planilhas/Treino para Mulheres - Continuidade Etapa 15.pdf";
import mc16 from "./planilhas/Treino para Mulheres - Continuidade Etapa 16.pdf";
import mc17 from "./planilhas/Treino para Mulheres - Continuidade Etapa 17.pdf";
import mc18 from "./planilhas/Treino para Mulheres - Continuidade Etapa 18.pdf";
import mc19 from "./planilhas/Treino para Mulheres - Continuidade Etapa 19.pdf";
import mc20 from "./planilhas/Treino para Mulheres - Continuidade Etapa 20.pdf";
import mc21 from "./planilhas/Treino para Mulheres - Continuidade Etapa 21.pdf";
import mc22 from "./planilhas/Treino para Mulheres - Continuidade Etapa 22.pdf";
import mc23 from "./planilhas/Treino para Mulheres - Continuidade Etapa 23.pdf";
import mc24 from "./planilhas/Treino para Mulheres - Continuidade Etapa 24.pdf";
import mc25 from "./planilhas/Treino para Mulheres - Continuidade Etapa 25.pdf";
import mc26 from "./planilhas/Treino para Mulheres - Continuidade Etapa 26.pdf";
import mc27 from "./planilhas/Treino para Mulheres - Continuidade Etapa 27.pdf";
import mc28 from "./planilhas/Treino para Mulheres - Continuidade Etapa 28.pdf";
import mc29 from "./planilhas/Treino para Mulheres - Continuidade Etapa 29.pdf";

import hc1 from "./planilhas/Treino para Homens - Continuidade Etapa 1.pdf";
import hc2 from "./planilhas/Treino para Homens - Continuidade Etapa 2.pdf";
import hc3 from "./planilhas/Treino para Homens - Continuidade Etapa 3.pdf";
import hc4 from "./planilhas/Treino para Homens - Continuidade Etapa 4.pdf";
import hc5 from "./planilhas/Treino para Homens - Continuidade Etapa 5.pdf";
import hc6 from "./planilhas/Treino para Homens - Continuidade Etapa 6.pdf";
import hc7 from "./planilhas/Treino para Homens - Continuidade Etapa 7.pdf";
import hc8 from "./planilhas/Treino para Homens - Continuidade Etapa 8.pdf";
import hc9 from "./planilhas/Treino para Homens - Continuidade Etapa 9.pdf";
import hc10 from "./planilhas/Treino para Homens - Continuidade Etapa 10.pdf";
import hc11 from "./planilhas/Treino para Homens - Continuidade Etapa 11.pdf";
import hc12 from "./planilhas/Treino para Homens - Continuidade Etapa 12.pdf";
import hc13 from "./planilhas/Treino para Homens - Continuidade Etapa 13.pdf";
import hc14 from "./planilhas/Treino para Homens - Continuidade Etapa 14.pdf";
import hc15 from "./planilhas/Treino para Homens - Continuidade Etapa 15.pdf";
import hc16 from "./planilhas/Treino para Homens - Continuidade Etapa 16.pdf";
import hc17 from "./planilhas/Treino para Homens - Continuidade Etapa 17.pdf";
import hc18 from "./planilhas/Treino para Homens - Continuidade Etapa 18.pdf";
import hc19 from "./planilhas/Treino para Homens - Continuidade Etapa 19.pdf";
import hc20 from "./planilhas/Treino para Homens - Continuidade Etapa 20.pdf";
import hc23 from "./planilhas/Treino para Homens - Continuidade Etapa 23.pdf";
import hc24 from "./planilhas/Treino para Homens - Continuidade Etapa 24.pdf";
import hc25 from "./planilhas/Treino para Homens - Continuidade Etapa 25.pdf";
import hc26 from "./planilhas/Treino para Homens - Continuidade Etapa 26.pdf";
import hc27 from "./planilhas/Treino para Homens - Continuidade Etapa 27.pdf";

import msp1 from "./planilhas/Treino para Mulheres que Treinam em casa sem peso.pdf";
import msp2 from "./planilhas/Treino para Mulheres que treinam em casa com peso do corpo - Etapa 2.pdf";
import msp3 from "./planilhas/Treino para Mulheres que treinam em casa com peso do corpo - Etapa 3.pdf";
import msp4 from "./planilhas/Treino para Mulheres que treinam em casa com peso do corpo - Etapa 4.pdf";
import thi1 from "./planilhas/Treino para Homens Iniciantes nível 1.pdf";
import thi2 from "./planilhas/Treino para Homens Iniciantes nivel 2.pdf";
import tmi1 from "./planilhas/Treino para Mulheres iniciantes nivel 1.pdf";
import tmi2 from "./planilhas/Treino para Mulheres iniciantes nivel 2.pdf";
import mhb1 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 1.pdf";
import mhb2 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 2.pdf";
import mhb3 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 3.pdf";
import mhb4 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 4.pdf";
import mhb5 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 5.pdf";
import mhb6 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 6.pdf";
import mhb7 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 7.pdf";
import mhb8 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 8.pdf";
import mhb9 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 9.pdf";
import mhb10 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 10.pdf";
import mhb11 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 11.pdf";
import mhb12 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 12.pdf";
import mhb13 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 13.pdf";
import mhb14 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 14.pdf";
import mhb15 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 15.pdf";
import mhb16 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 16.pdf";
import mhb17 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 17.pdf";
import mhb18 from "./planilhas/Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 18.pdf";
import m17 from "./planilhas/17-metodos-darlan-furtado.pdf";
import guiadefinicao from "./planilhas/guia-rapido-e-facil-para-definicao-muscular-darlan-furtado.pdf";
import tmpg from "./planilhas/Treino para mulheres com ênfase nas pernas e glúteos.pdf";

/* cardapios - inicio */
import alfa from "./cardapios/Cardápio Alfa Hipertrofia.pdf";
import bravo from "./cardapios/Cardápio Bravo Perda de Gordura.pdf";
import charlie from "./cardapios/Cardápio Charlie Hipertrofia.pdf";
import delta from "./cardapios/Cardápio Delta Perda de Gordura.pdf";
import echo from "./cardapios/Cardápio Echo Hipertrofia.pdf";
import foxtrot from "./cardapios/Cardápio Foxtrot Perda de Gordura.pdf";
import golf from "./cardapios/Cardápio Golf Hipertrofia.pdf";
import hotel from "./cardapios/Cardápio Hotel Perda de Gordura.pdf";
import india from "./cardapios/Cardápio India Hipertrofia.pdf";
import juliet from "./cardapios/Cardápio Juliet Perda de Gordura.pdf";
import kilo from "./cardapios/Cardápio Kilo Hipertrofia.pdf";
import lima from "./cardapios/Cardápio Lima Perda de Gordura.pdf";
import mike from "./cardapios/Cardápio Mike Hipertrofia.pdf";
import november from "./cardapios/Cardápio November Perda de Gordura.pdf";
import oscar from "./cardapios/Cardápio Oscar Hipertrofia.pdf";
import papa from "./cardapios/Cardápio Papa Perda de Gordura.pdf";
import quebec from "./cardapios/Cardápio Quebec Hipertrofia.pdf";
import romeu from "./cardapios/Cardápio Romeu Perda de Gordura.pdf";
/* cardapios - fim */

/* cardapios - inicio */
import barcelona from "./cardapios/Cárdapio Barcelona Perda de Gordura.pdf";
import genebra from "./cardapios/Cárdapio Genebra Hipertrofia.pdf";
import atenas from "./cardapios/Cárdapio Atenas Perda de Gordura.pdf";
import cairo from "./cardapios/Cárdapio Cairo Hipertrofia.pdf";
import dublin from "./cardapios/Cárdapio Dublin Perda de Gordura.pdf";
import dubai from "./cardapios/Cárdapio Dubai Hipertrofia.pdf";

/* cardapios - fim */
import avestruz from "./cardapios/Cardápio Avestruz.pdf";
import borboleta from "./cardapios/Cardápio Borboleta.pdf";
import camaleao from "./cardapios/Cardápio Camaleão.pdf";

/* cardapios - inicio */


/* cardapios - fim */
const listaProdutos = [
  {
    id: 0,
    nome: "Treino para Mulheres - Continuidade Etapa 1",
    link: mc1,
  },
  {
    id: 6,
    nome: "Treino para Mulheres - Continuidade Etapa 2",
    link: mc2,
  },
  {
    id: 1,
    nome: "Treino para Mulheres - Continuidade Etapa 3",
    link: mc3,
  },
  {
    id: 2,
    nome: "Treino para Mulheres - Continuidade Etapa 4",
    link: mc4,
  },
  {
    id: 3,
    nome: "Treino para Mulheres - Continuidade Etapa 5",
    link: mc5,
  },
  {
    id: 7,
    nome: "Treino para Mulheres - Continuidade Etapa 6",
    link: mc6,
  },
  {
    id: 4,
    nome: "Treino para Mulheres - Continuidade Etapa 7",
    link: mc7,
  },
  {
    id: 23,
    nome: "Treino para Mulheres - Continuidade Etapa 8",
    link: mc8,
  },
  {
    id: 5,
    nome: "Treino para Mulheres - Continuidade Etapa 9",
    link: mc9,
  },
  {
    id: 21,
    nome: "Treino para Mulheres - Continuidade Etapa 10",
    link: mc10,
  },
  {
    id: 29,
    nome: "Treino para Mulheres - Continuidade Etapa 11",
    link: mc11,
  },
  {
    id: 32,
    nome: "Treino para Mulheres - Continuidade Etapa 12",
    link: mc12,
  },
  {
    id: 35,
    nome: "Treino para Mulheres - Continuidade Etapa 13",
    link: mc13,
  },
  {
    id: 45,
    nome: "Treino para Mulheres - Continuidade Etapa 14",
    link: mc14,
  },
  {
    id: 425,
    nome: "Treino para Mulheres - Continuidade Etapa 15",
    link: mc15,
  },
  {
    id: 435,
    nome: "Treino para Mulheres - Continuidade Etapa 16",
    link: mc16,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 17",
    link: mc17,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 18",
    link: mc18,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 19",
    link: mc19,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 20",
    link: mc20,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 21",
    link: mc21,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 22",
    link: mc22,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 23",
    link: mc23,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 24",
    link: mc24,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 25",
    link: mc25,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 26",
    link: mc26,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 27",
    link: mc27,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 28",
    link: mc28,
  },
  {
    id: 4351,
    nome: "Treino para Mulheres - Continuidade Etapa 29",
    link: mc29,
  },

  {
    id: 26,
    nome: "Treino para Mulheres iniciantes nivel 1",
    link: tmi1,
  },
  {
    id: 19,
    nome: "Treino para Mulheres iniciantes nivel 2",
    link: tmi2,
  },
  {
    id: 16,
    nome: "Treino para Homens - Continuidade Etapa 1",
    link: hc1,
  },
  {
    id: 15,
    nome: "Treino para Homens - Continuidade Etapa 2",
    link: hc2,
  },
  {
    id: 8,
    nome: "Treino para Homens - Continuidade Etapa 3",
    link: hc3,
  },
  {
    id: 10,
    nome: "Treino para Homens - Continuidade Etapa 4",
    link: hc4,
  },
  {
    id: 13,
    nome: "Treino para Homens - Continuidade Etapa 5",
    link: hc5,
  },
  {
    id: 12,
    nome: "Treino para Homens - Continuidade Etapa 6",
    link: hc6,
  },
  {
    id: 11,
    nome: "Treino para Homens - Continuidade Etapa 7",
    link: hc7,
  },
  {
    id: 22,
    nome: "Treino para Homens - Continuidade Etapa 8",
    link: hc8,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 9",
    link: hc9,
  },
  {
    id: 2222,
    nome: "Treino para Homens - Continuidade Etapa 10",
    link: hc10,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 11",
    link: hc11,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 12",
    link: hc12,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 13",
    link: hc13,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 14",
    link: hc14,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 15",
    link: hc15,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 16",
    link: hc16,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 17",
    link: hc17,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 18",
    link: hc18,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 19",
    link: hc19,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 20",
    link: hc20,
  },
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 23",
    link: hc23,
  },
  
  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 24",
    link: hc24,
  },

  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 25",
    link: hc25,
  },

  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 26",
    link: hc26,
  },

  {
    id: 222,
    nome: "Treino para Homens - Continuidade Etapa 27",
    link: hc27,
  },
  {
    id: 33,
    nome: "Treino para Homens Iniciantes nível 1",
    link: thi1,
  },
  {
    id: 18,
    nome: "Treino para Homens Iniciantes nivel 2",
    link: thi2,
  },
  {
    id: 17,
    nome: "Treino para Mulheres que treinam em casa com peso do corpo - Etapa 1",
    link: msp1,
  },
  {
    id: 25,
    nome: "Treino para Mulheres que treinam em casa com peso do corpo - Etapa 2",
    link: msp2,
  },

  {
    id: 24,
    nome: "Treino para Mulheres que treinam em casa com peso do corpo - Etapa 3",
    link: msp3,
  },
  {
    id: 28,
    nome: "Treino para Mulheres que treinam em casa com peso do corpo - Etapa 4",
    link: msp4,
  },

  {
    id: 36,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 1",
    link: mhb1,
  },
  {
    id: 9,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 2",
    link: mhb2,
  },

  {
    id: 27,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 3",
    link: mhb3,
  },
  {
    id: 20,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 4",
    link: mhb4,
  },
  {
    id: 34,

    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 5",
    link: mhb5,
  },
  {
    id: 35,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 6",

    link: mhb6,
  },
  {
    id: 325,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 7",
    link: mhb7,
  },
  {
    id: 39,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 8",

    link: mhb8,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 9",
    link: mhb9,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 10",
    link: mhb10,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 11",
    link: mhb11,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 12",
    link: mhb12,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 13",
    link: mhb13,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 14",
    link: mhb14,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 15",
    link: mhb15,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 16",
    link: mhb16,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 17",
    link: mhb17,
  },
  {
    id: 339,
    nome: "Treino para Mulheres que treinam em casa com HALTERES E BARRAS - Etapa 18",
    link: mhb18,
  },
  {
    id: 30,
    nome: "Treino para mulheres com ênfase nas pernas e glúteos",
    link: tmpg,
  },
  {
    id: 14,
    nome: "17-metodos-darlan-furtado",
    link: m17,
  },
  {
    id: 15,
    nome: "guia-rapido-e-facil-para-definicao-muscular-darlan-furtado",
    link: guiadefinicao,
  },

  /* cardapios - inicio */
  {
    id: 1401,
    nome: "Cardápio Alfa Hipertrofia",
    link: alfa,
  },
  {
    id: 1402,
    nome: "Cardápio Bravo Perda de Gordura",
    link: bravo,
  },
  {
    id: 1403,
    nome: "Cardápio Charlie Hipertrofia",
    link: charlie,
  },
  {
    id: 1404,
    nome: "Cardápio Delta Perda de Gordura",
    link: delta,
  },
  {
    id: 1405,
    nome: "Cardápio Echo Hipertrofia",
    link: echo,
  },
  {
    id: 1406,
    nome: "Cardápio Foxtrot Perda de Gordura",
    link: foxtrot,
  },
  {
    id: 1407,
    nome: "Cardápio Golf Hipertrofia",
    link: golf,
  },
  {
    id: 1408,
    nome: "Cardápio Hotel Perda de Gordura",
    link: hotel,
  },
  {
    id: 1409,
    nome: "Cardápio India Hipertrofia",
    link: india,
  },
  {
    id: 1410,
    nome: "Cardápio Juliet Perda de Gordura",
    link: juliet,
  },
  {
    id: 1411,
    nome: "Cardápio Kilo Hipertrofia",
    link: kilo,
  },
  {
    id: 1412,
    nome: "Cardápio Lima Perda de Gordura",
    link: lima,
  },
  {
    id: 1413,
    nome: "Cardápio Mike Hipertrofia",
    link: mike,
  },
  {
    id: 1414,
    nome: "Cardápio November Perda de Gordura",
    link: november,
  },
  {
    id: 1415,
    nome: "Cardápio Oscar Hipertrofia",
    link: oscar,
  },
  {
    id: 1416,
    nome: "Cardápio Papa Perda de Gordura",
    link: papa,
  },
  {
    id: 1417,
    nome: "Cardápio Quebec Hipertrofia",
    link: quebec,
  },

  {
    id: 1418,
    nome: "Cardápio Romeu Perda de Gordura",
    link: romeu,
  },

  /* cardapios - fim */
  /* cardapios ciclo carbo */

  {
    id: 1500,
    nome: "Cárdapio Atenas Perda de Gordura",
    link: atenas,
  },
  {
    id: 1500,
    nome: "Cárdapio Barcelona Perda de Gordura",
    link: barcelona,
  },


  {
    id: 1500,
    nome: "Cárdapio Genebra Hipertrofia",
    link: genebra,
  },
  {
    id: 1500,
    nome: "Cárdapio Dublin Perda de Gordura",
    link: dublin,
  },
  {
    id: 1500,
    nome: "Cárdapio Dubai Hipertrofia",
    link: dubai,
  },
  {
    id: 1500,
    nome: "Cárdapio Cairo Hipertrofia",
    link: cairo,
  },

  
  
  /* cardapios ciclo carbo */

  {
    id: 1500,
    nome: "Cardápio Avestruz",
    link: avestruz,
  },
  {
    id: 1500,
    nome: "Cardápio Borboleta",
    link: borboleta,
  },
  {
    id: 1500,
    nome: "Cardápio Camaleão",
    link: camaleao,
  },
];

export default listaProdutos;
